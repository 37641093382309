import React, { useEffect, useState } from 'react';
import {
    createNoticia,
    getNoticias,
    updateNoticia,
    deleteNoticia,
} from '../../../services/NoticiasService';
import { storage } from '../../../firebaseConfig';
import { ref, deleteObject } from 'firebase/storage';
import styles from './AdminNoticias.module.css';
import PopUpAdminComponent from '../../PopUpAdmin/PopUpAdminComponent';
import AdminCreateNoticias from './AdminCreateNoticias';
import AdminEditNoticias from './AdminEditNoticias';

const AdminNoticias = () => {
    const [noticias, setNoticias] = useState([]);
    const [editId, setEditId] = useState(null);
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [noticiaData, setNoticiaData] = useState(null);

    useEffect(() => {
        fetchNoticias();
    }, []);

    const fetchNoticias = async () => {
        try {
            const noticiasData = await getNoticias();
            setNoticias(noticiasData);
        } catch (error) {
            console.error('Erro ao buscar notícias:', error);
        }
    };

    const handleCreateNoticia = async () => {
        try {
            await createNoticia();
            fetchNoticias();
            setIsCreatePopupOpen(false);
        } catch (error) {
            console.error('Erro ao criar notícia:', error);
        }
    };

    const handleUpdateNoticia = async () => {
        try {
            await updateNoticia(editId);
            setEditId(null);
            fetchNoticias();
            setIsEditPopupOpen(false);
        } catch (error) {
            console.error('Erro ao atualizar notícia:', error);
        }
    };

    const startEdit = (noticia) => {
        setEditId(noticia._id);
        setNoticiaData(noticia);
        setIsEditPopupOpen(true);
    };

    const deleteImageFromFirebase = async (imageUrl) => {
        if (!imageUrl || !imageUrl.includes('firebasestorage.googleapis.com')) {
            console.warn('URL não é válida ou não pertence ao Firebase Storage, ignorando...');
            return;
        }

        const imageRef = ref(storage, imageUrl);
        try {
            await deleteObject(imageRef);
            console.log('Imagem deletada com sucesso do Firebase.');
        } catch (error) {
            if (error.code === 'storage/object-not-found') {
                console.warn('Imagem não encontrada no Firebase, ignorando...');
            } else {
                console.error('Erro ao deletar a imagem do Firebase:', error);
            }
        }
    };

    const handleDeleteNoticia = async (id, imageUrl) => {
        console.log("ID enviado para exclusão:", id); // Log para verificar o ID
    
        if (!id) {
            console.error('ID inválido para deletar a notícia.');
            return;
        }
    
        try {
            // Deleta a imagem associada no Firebase, se existir
            await deleteImageFromFirebase(imageUrl);
    
            // Envia a solicitação para o backend excluir a notícia
            const response = await deleteNoticia(id);
            console.log("Resposta do backend:", response); // Log para verificar a resposta
    
            // Atualiza a lista de notícias no frontend
            setNoticias((prevNoticias) => prevNoticias.filter((noticia) => noticia._id !== id));
            console.log('Notícia deletada com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar a notícia:', error);
        }
    };
    
    

    const filteredNoticias = noticias.filter((noticia) =>
        noticia.titulo?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h2>Gerenciar Notícias</h2>
                <div className={styles.actionsHeader}>
                    <input
                        type="text"
                        placeholder="Buscar por título..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={styles.searchInput}
                    />
                    <button onClick={() => setIsCreatePopupOpen(true)} className={styles.addButton}>
                        + Nova Notícia
                    </button>
                </div>
            </header>

            {isCreatePopupOpen && (
                <PopUpAdminComponent title="Criar Notícia" onClose={() => setIsCreatePopupOpen(false)}>
                    <AdminCreateNoticias onCreate={handleCreateNoticia} />
                </PopUpAdminComponent>
            )}

            {isEditPopupOpen && noticiaData && (
                <PopUpAdminComponent title="Editar Notícia" onClose={() => setIsEditPopupOpen(false)}>
                    <AdminEditNoticias
                        onUpdate={handleUpdateNoticia}
                        noticiaId={editId}
                        noticiaData={noticiaData}
                    />
                </PopUpAdminComponent>
            )}

            <div className={styles.noticiasList}>
                {filteredNoticias.map((noticia) => (
                    <div key={noticia._id} className={styles.noticiaItem}>
                        <div className={styles.noticiaContent}>
                            <h3 className={styles.noticiaTitle}>{noticia.titulo || 'Título não definido'}</h3>
                            <p className={styles.noticiaDescription}>{noticia.textoDescription || 'Sem descrição'}</p>
                            <small className={styles.noticiaDate}>
                                {noticia.data ? new Date(noticia.data).toLocaleDateString() : 'Sem data'}
                            </small>
                        </div>
                        {noticia.imagem && <img src={noticia.imagem} alt="Notícia" className={styles.image} />}
                        {noticia.video && (
                            <a
                                href={noticia.video}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.videoLink}
                            >
                                Ver vídeo
                            </a>
                        )}
                        <div className={styles.actions}>
                            <button onClick={() => startEdit(noticia)} className={styles.editButton}>
                                Editar
                            </button>
                            <button
                                onClick={() => handleDeleteNoticia(noticia._id, noticia.imagem)}
                                className={styles.deleteButton}
                            >
                                Excluir
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminNoticias;
