import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import styles from './PopUpComponent.module.css';

const PopUpComponent = ({ isOpen, onClose, children, background }) => {
  if (!isOpen) return null;

  const handleMouseEnter = (e) => {
    e.currentTarget.classList.remove(styles.spinBackward);
    e.currentTarget.classList.add(styles.spinForward);
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.classList.remove(styles.spinForward);
    e.currentTarget.classList.add(styles.spinBackward);
  };

  return (
    <div className={styles.overlay}>
      <div style={{ background: background }} className={styles.modalContent}>
        <AiOutlineClose
          className={styles.closeButton}
          onClick={onClose}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default PopUpComponent;
