import React, { useState } from 'react';
import { createNoticia } from '../../../services/NoticiasService';
import styles from './AdminCreateNoticias.module.css';
import { storage } from '../../../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const AdminCreateNoticias = ({ onCreate }) => {
    const [titulo, setTitulo] = useState('');
    const [data, setData] = useState('');
    const [textoDescription, setTextoDescription] = useState('');
    const [texto, setTexto] = useState('');
    const [imagem, setImagem] = useState('');
    const [video, setVideo] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (!file || !file.type.startsWith('image/')) {
            alert('Por favor, selecione um arquivo de imagem válido.');
            return;
        }

        setIsUploading(true);
        const storageRef = ref(storage, `noticias/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error('Erro ao fazer upload da imagem:', error);
                setIsUploading(false);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                setImagem(downloadURL);
                setIsUploading(false);
            }
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isUploading) {
            alert('Aguarde o término do upload da imagem.');
            return;
        }

        if (titulo && data && textoDescription && texto && imagem) {
            await createNoticia({
                titulo,
                data,
                textoDescription,
                texto,
                imagem,
                video,
            });
            onCreate(); // Atualiza a lista e fecha o popup
            resetForm(); // Reseta o formulário
        } else {
            alert('Preencha todos os campos obrigatórios.');
        }
    };

    const resetForm = () => {
        setTitulo('');
        setData('');
        setTextoDescription('');
        setTexto('');
        setImagem('');
        setVideo('');
        setUploadProgress(0);
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <input
                type="text"
                placeholder="Título"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="date"
                placeholder="Data"
                value={data}
                onChange={(e) => setData(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="text"
                placeholder="Descrição"
                value={textoDescription}
                onChange={(e) => setTextoDescription(e.target.value)}
                className={styles.input}
                required
            />
            <textarea
                placeholder="Texto completo"
                value={texto}
                onChange={(e) => setTexto(e.target.value)}
                className={styles.textarea}
                required
            ></textarea>
            <input
                type="file"
                onChange={handleImageUpload}
                className={styles.input}
                required
            />
            
            <p>Tamanho ideal: *1280x720px</p>
            {uploadProgress > 0 && uploadProgress < 100 && (
                <p>Progresso do upload: {Math.round(uploadProgress)}%</p>
            )}
            {imagem && <img src={imagem} alt="Pré-visualização da imagem" className={styles.preview} />}
            <input
                type="text"
                placeholder="URL do Vídeo"
                value={video}
                onChange={(e) => setVideo(e.target.value)}
                className={styles.input}
            />
            <button type="submit" className={styles.button} disabled={isUploading}>
                {isUploading ? 'Aguarde...' : 'Criar Notícia'}
            </button>
        </form>
    );
};

export default AdminCreateNoticias;
