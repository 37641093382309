import React, { useState, useEffect } from 'react';
import styles from './CookiesConsent.module.css';
import PDF_PoliticaPrivacidades from '../../assets/contratos/Política de privacidade e termo de uso FINALIZADO ALTERNATIVO.pdf';
import PDF_TermoConcentimento from '../../assets/contratos/TERMO DE CONSENTIMENTO.pdf';

const CookiesConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookiesConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={styles.cookiesConsent}>
      <div className={styles.messageContainer}>
        <p>
          🍪 Utilizamos cookies para melhorar sua experiência. Ao continuar navegando, você concorda com nossa{' '}
          <a
            href={PDF_PoliticaPrivacidades}
            download="Politica_de_Privacidade.pdf"
            className={styles.link}
          >
            Política de Privacidade
          </a>{' '}
          e{' '}
          <a
            href={PDF_TermoConcentimento}
            download="Termo_de_Consentimento.pdf"
            className={styles.link}
          >
            Termo de Consentimento
          </a>.
        </p>
      </div>
      <button onClick={handleAccept} className={styles.acceptButton}>
        Aceitar
      </button>
    </div>
  );
};

export default CookiesConsent;
