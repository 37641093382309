// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // Importar Firestore
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAkXky2yt2TmDin26J2Z-zCABCYy8KCJ4o",
  authDomain: "mmart-e106f.firebaseapp.com",
  projectId: "mmart-e106f",
  storageBucket: "mmart-e106f.appspot.com",
  messagingSenderId: "394337902425",
  appId: "1:394337902425:web:fa28c9b1cec100d753f648",
  measurementId: "G-DY093TV6FQ"
};


// Inicializa o Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Inicializar Firestore
const storage = getStorage(app); 

const user = auth.currentUser;
if (user) {
  console.log('Usuário autenticado:', user.email);
  const token = await user.getIdToken();
  console.log('Token:', token);
} else {
  console.error('Nenhum usuário autenticado.');
}

export { auth, db ,storage };
