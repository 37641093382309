import apiAxios from '../api/apiAxios';
const BASE_URL = '/noticias';

// Função para obter todas as notícias
export const getNoticias = async () => {
    const response = await apiAxios.get(BASE_URL);
    return response.data;
};

export const getUltimasNoticias = async () => {
    const response = await apiAxios.get(`${BASE_URL}/ultimas`);
    return response.data;
};

export const getTodasUltimasNoticias = async () => {
    const response = await apiAxios.get(`${BASE_URL}/all-ultimas`);
    return response.data;
};

// Função para obter uma notícia por ID
export const getNoticiaById = async (id) => {
    const response = await apiAxios.get(`${BASE_URL}/${id}`);
    return response.data;
};

export const getNoticiaByTitulo = async (titulo) => {
    const encodedTitulo = encodeURIComponent(titulo); // Codifica o título para lidar com espaços e caracteres especiais
    const response = await apiAxios.get(`${BASE_URL}/titulo/${encodedTitulo}`);
    return response.data;
};

// Função para criar uma nova notícia
export const createNoticia = async (noticiaData) => {
    const response = await apiAxios.post(BASE_URL, noticiaData);
    return response.data;
};

// Função para atualizar uma notícia por ID
export const updateNoticia = async (id, noticiaData) => {
    const response = await apiAxios.put(`${BASE_URL}/${id}`, noticiaData);
    return response.data;
};

// Função para deletar uma notícia por ID
export const deleteNoticia = async (id) => {
    const response = await apiAxios.delete(`${BASE_URL}/${id}`);
    return response.data;
};
