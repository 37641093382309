import React, { useEffect } from 'react';
import AOS from 'aos'; // Importa a biblioteca AOS
import 'aos/dist/aos.css'; // Importa os estilos do AOS
import styles from './SobreSection.module.css';

const SobreSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duração da animação em milissegundos
      once: true, // Executa a animação apenas uma vez
    });
  }, []);

  return (
    <section className={styles.sobreContainer}>
      <h2
        className={styles.title}
        data-aos="fade-right" // Animação vindo da direita
      >
        Há 28 Anos Construindo Laços e Saberes
      </h2>
      <p
        className={styles.subtitle}
        data-aos="fade-right" // Animação vindo da direita
        data-aos-delay="200" // Atraso em milissegundos
      >
        No Colégio Alternativo Colaço, promovemos uma educação integral que valoriza o desenvolvimento cognitivo, emocional e social de cada aluno.
      </p>
      <p
        className={styles.description}
        data-aos="fade-right" // Animação vindo da direita
        data-aos-delay="400"
      >
        Nosso <strong>projeto pedagógico</strong> é centrado no aluno, respeitando suas individualidades e potencialidades. Oferecemos atividades que estimulam o aprendizado e preparam nossos estudantes para os desafios do futuro.
      </p>
      <p
        className={styles.description}
        data-aos="fade-right" // Animação vindo da direita
        data-aos-delay="600"
      >
        Com uma equipe dedicada e um ambiente acolhedor, proporcionamos experiências educativas significativas desde a Educação Infantil até o Ensino Fundamental I.
      </p>
      <div
        className={styles.buttonContainer}
        data-aos="fade-up" // Animação vindo de baixo
        data-aos-delay="800"
      >
        <p className={styles.buttonInfo}>
          Para agendar uma visita ou esclarecer dúvidas, clique no botão abaixo:
        </p>
        <button
          className={styles.actionButton}
          onClick={() => (window.location.href = '/contato')}
        >
          📋 Informações / Matrículas
        </button>
      </div>
    </section>
  );
};

export default SobreSection;
