import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styles from './AdminHeader2Edit.module.css';
import { storage } from '../../../firebaseConfig';
import Header2Service from '../../../services/Header2Service';

const AdminHeader2Edit = ({ onUpdate, bannerId, bannerData, onClose }) => {
    const [titulo, setTitulo] = useState('');
    const [desktopFile, setDesktopFile] = useState(null);
    const [mobileFile, setMobileFile] = useState(null);
    const [desktopPreview, setDesktopPreview] = useState('');
    const [mobilePreview, setMobilePreview] = useState('');
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (bannerData) {
            setTitulo(bannerData.titulo || '');
            setDesktopPreview(bannerData.imagemDesktop || '');
            setMobilePreview(bannerData.imagemMobile || '');
        }
    }, [bannerData]);

    const handleFileUpload = async (file, path) => {
        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                null,
                (error) => reject(error),
                async () => {
                    const url = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(url);
                }
            );
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUploading(true);

        try {
            const updatedData = { titulo };

            // Upload de imagem desktop se foi alterada
            if (desktopFile) {
                updatedData.imagemDesktop = await handleFileUpload(
                    desktopFile,
                    `banners/${bannerId}_desktop.jpg`
                );
            }

            // Upload de imagem mobile se foi alterada
            if (mobileFile) {
                updatedData.imagemMobile = await handleFileUpload(
                    mobileFile,
                    `banners/${bannerId}_mobile.jpg`
                );
            }

            await Header2Service.update(bannerId, updatedData);
            onUpdate(); // Atualiza a lista no componente pai
            onClose(); // Fecha o modal
        } catch (error) {
            console.error('Erro ao atualizar banner:', error);
        } finally {
            setUploading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <input
                type="text"
                placeholder="Título"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                className={styles.input}
                required
            />
            <div className={styles.fileUpload}>
                <label>
                    Imagem Desktop:
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setDesktopFile(file);
                            setDesktopPreview(URL.createObjectURL(file));
                        }}
                    />
                </label>
                {desktopPreview && (
                    <img src={desktopPreview} alt="Preview Desktop" className={styles.previewImage} />
                )}
            </div>
            <div className={styles.fileUpload}>
                <label>
                    Imagem Mobile:
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setMobileFile(file);
                            setMobilePreview(URL.createObjectURL(file));
                        }}
                    />
                </label>
                {mobilePreview && (
                    <img src={mobilePreview} alt="Preview Mobile" className={styles.previewImage} />
                )}
            </div>
            <p>*Desktop: 1920x1080 ou maior.</p>
            <p>*Mobile: 768x1024.</p>
            {uploading ? (
                <p>Fazendo upload...</p>
            ) : (
                <button type="submit" className={styles.button}>
                    Salvar Alterações
                </button>
            )}
        </form>
    );
};

export default AdminHeader2Edit;
