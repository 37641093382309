import React, { useState, useEffect, useRef } from 'react';
import styles from './HeroSection.module.css';
import Header1Service from '../../../../services/Header1Service';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner'; // Biblioteca para o spinner

const HeroSection = () => {
  const [headers, setHeaders] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(true);
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHeaders = async () => {
      try {
        const data = await Header1Service.getAll();
        if (data && data.length > 0) {
          setHeaders(data);
        } else {
          console.warn('Nenhum dado disponível.');
        }
      } catch (error) {
        console.error('Erro ao carregar os headers:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchHeaders();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (headers.length > 0) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % headers.length);
      }, 5000);

      return () => clearInterval(intervalRef.current);
    }
  }, [headers]);

  const validHeaders = headers.filter(
    (header) => header.imagemDesktop && header.imagemMobile
  );

  useEffect(() => {
    if (currentSlide >= validHeaders.length) {
      setCurrentSlide(0);
    }
  }, [currentSlide, validHeaders.length]);

  return (
    <div className={styles.heroContainer}>
      {isLoading ? (
        <div className={styles.spinnerContainer}>
          <Oval
            height={80}
            width={80}
            color="#ffd700"
            secondaryColor="#ca9610"
            visible={true}
            ariaLabel="Carregando"
          />
        </div>
      ) : validHeaders.length > 0 ? (
        validHeaders.map((header, index) => (
          <div
            key={header._id}
            className={`${styles.heroSlide} ${index === currentSlide ? styles.active : ''}`}
            style={{
              backgroundImage: `url(${isMobile ? header.imagemMobile : header.imagemDesktop})`
            }}
          >
            <div className={styles.overlay}>
              <div className={styles.textContainer}>
                <p className={styles.subtitle}>{header.subTitulo || 'Subtítulo não disponível'}</p>
                <h1 className={styles.title}>{header.titulo || 'Título não disponível'}</h1>
                <button className={styles.heroButton} onClick={() => navigate('/matriculas')}>
                  Saiba Mais
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className={styles.noData}>Nenhum dado disponível para exibição.</p>
      )}
    </div>
  );
};

export default HeroSection;
