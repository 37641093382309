import React from 'react';
import styles from './Contato.module.css';
import TituloComponent from '../components/Titulo/TituloComponent';
import { FaWhatsapp } from 'react-icons/fa'; // Importação do ícone de WhatsApp

const Contato = () => {
    const setores = [
        {
            nome: 'Diretoria',
            descricao: 'Questões financeiras, matrículas e dúvidas gerais.',
            email: 'colegioalternativocolaco@gmail.com',
            whatsapp: 'https://api.whatsapp.com/send/?phone=5585988068633&text=Ol%C3%A1%2C+encontrei+o+site+do+Col%C3%A9gio+Alternativo+Cola%C3%A7o+e+estou+interessado+em+realizar+uma+matr%C3%ADcula+ou+efetuar+um+pagamento.+Poderiam+me+auxiliar%3F&type=phone_number&app_absent=0',
        },
        {
            nome: 'Secretaria',
            descricao: 'Questões relacionadas a declarações, documentações e dúvidas em geral.',
            email: 'colegioalternativocolaco@gmail.com',
            whatsapp: 'https://api.whatsapp.com/send/?phone=558588987899&text=Ol%C3%A1%2C+encontrei+o+site+do+Col%C3%A9gio+Alternativo+Cola%C3%A7o+e+gostaria+de+solicitar+uma+documenta%C3%A7%C3%A3o+espec%C3%ADfica.+Poderiam+me+informar+como+proceder%3F&type=phone_number&app_absent=0',
        },
        {
            nome: 'Coordenação',
            descricao: 'Assuntos relacionados a provas, contato com os pais e desempenho acadêmico.',
            email: 'colegioalternativocolaco@gmail.com',
            whatsapp: 'https://api.whatsapp.com/send/?phone=558589579305&text=Ol%C3%A1%2C+encontrei+o+site+do+Col%C3%A9gio+Alternativo+Cola%C3%A7o+e+estou+com+d%C3%BAvidas+em+rela%C3%A7%C3%A3o+%C3%A0s+quest%C3%B5es+pedag%C3%B3gicas.+Poderiam+me+ajudar%3F&type=phone_number&app_absent=0',
        },
    ];

    return (
        <>
            <TituloComponent name="Contato" />
            <div className={styles.contatoContainer}>
                <p className={styles.pageDescription}>
                    Entre em contato com o setor correspondente à sua necessidade. Estamos prontos para atendê-lo!
                </p>

                <div className={styles.sectorsContainer}>
                    {setores.map((setor, index) => (
                        <div key={index} className={styles.setorCard}>
                            <h2 className={styles.setorTitle}>{setor.nome}</h2>
                            <p className={styles.setorDescription}>{setor.descricao}</p>
                            <p className={styles.contactInfo}>
                                <strong>Email:</strong>{' '}
                                <a href={`mailto:${setor.email}`}>{setor.email}</a>
                            </p>
                            <p className={styles.contactInfowhatzap}>
                                <a
                                    href={`https://api.whatsapp.com/send/?phone=${setor.whatsapp}&text=Olá,+gostaria+de+mais+informações`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.whatsappButton}
                                >
                                    <FaWhatsapp className={styles.whatsappIcon} />
                                    Enviar Mensagem
                                </a>
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Contato;
