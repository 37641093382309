import React, { useEffect } from "react";
import AOS from "aos"; // Importa o AOS
import "aos/dist/aos.css"; // Importa os estilos do AOS
import styles from "./VideoSection.module.css";

const VideoSec = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const enrollmentYear = currentMonth >= 6 ? currentYear + 1 : currentYear;

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duração da animação em milissegundos
      once: true, // Executa a animação apenas uma vez
    });
  }, []);

  const handleScheduleClick = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=5585988068633&text=Ol%C3%A1%2C+encontrei+o+site+do+Col%C3%A9gio+Alternativo+Cola%C3%A7o+e+gostaria+de+agendar+uma+visita.+Poderiam+me+ajudar%3F&type=phone_number&app_absent=0",
      "_blank"
    );
  };

  return (
    <div className={styles.videoContainer}>
      <div
        className={styles.videoWrapper}
        data-aos="fade-up" // Animação para o vídeo subir ao aparecer
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/n1fcL9pLpkM?si=xvDBuwNLmhMDw9d0"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className={styles.textWrapper}>
        <p
          className={styles.enrollmentYear}
          data-aos="fade-right" // Animação vinda da direita
        >
          MATRÍCULAS {enrollmentYear}
        </p>
        <h2
          className={styles.title}
          data-aos="fade-right"
          data-aos-delay="200" // Pequeno atraso na animação
        >
          Venha fazer parte do Colégio Alternativo Colaço!
        </h2>
        <p
          className={styles.subtitle}
          data-aos="fade-right"
          data-aos-delay="400"
        >
          AGENDE AGORA!
        </p>
        <p
          className={styles.description}
          data-aos="fade-right"
          data-aos-delay="600"
        >
          Estamos preparados para oferecer uma educação diferenciada, com
          tecnologia e inovação, valorizando o crescimento integral dos nossos
          alunos em um ambiente acolhedor e estimulante.
        </p>
        <p
          className={styles.description}
          data-aos="fade-right"
          data-aos-delay="800"
        >
          Faça parte de um futuro brilhante conosco!
        </p>
        <button
          className={styles.scheduleButton}
          onClick={handleScheduleClick}
          data-aos="fade-up" // Animação vinda de baixo
          data-aos-delay="1000"
        >
          📅 AGENDAR
        </button>
      </div>
    </div>
  );
};

export default VideoSec;
