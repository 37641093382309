import React, { useEffect, useState } from "react";
import AOS from "aos"; // Importa o AOS
import "aos/dist/aos.css"; // Importa os estilos do AOS
import styles from "./DepoimentosSection.module.css";
import img1 from "../../../../assets/depoimentosImages/465649094_1092656959532212_2145984958826588271_n.jpg";
import img2 from "../../../../assets/depoimentosImages/123318828_3476723165744762_3517766738332112945_n.jpg";
import img3 from "../../../../assets/depoimentosImages/126484267_3533782986705446_5330726696016005176_n.jpg";
import img4 from "../../../../assets/depoimentosImages/124250525_3496246433792435_1492975098561701938_n.jpg";

const testimonials = [
  {
    text: 'Super feliz em compartilhar minha história com a Escola Alternativo! Estudei aqui há 26 anos atrás, no Infantil I e continuei na escola durante um bom tempo, e agora minha filha Sophia vai começar no próximo ano. É incrível pensar que ela vai vivenciar tudo o que eu vivi.',
    author: 'Giderlanny Souza',
    image: img1,
  },
  {
    text: 'O Colégio Alternativo, para mim, durante muitos anos foi um apoio e uma segunda casa em que confiava de olhos fechados. Sou muito grata a escola por contribuir, de forma significativa, na formação dos meus filhos. Obrigada a todos que formam a família Alternativo! Heloísa, Mãe do Arthur(Arquiteto) e da Anne (Acadêmica de Enfermagem-UFC).',
    author: 'Arthur(Arquiteto) e da Anne (Acadêmica de Enfermagem-UFC).',
    image: img2,
  },
  {
    text: 'Serei eternamente grata ao Colégio Alternativo pois meus filhos Marina e Pedro estudaram desde 1 ano e sete meses de vida. Este colégio preza por um ambiente acolhedor, familiar e de valores cristãos. Eles passavam o dia todo na escola e o ensino é de qualidade. Eternamente grata 🙏🏻. Parabéns Colégio Alternativo. Super indico. 👏🏻👏🏻👏🏻👏🏻👏🏻👏🏻',
    author: 'Sandra Lima',
    image: img3,
  },
  {
    text: 'A foto à esquerda sou eu, com aproximadamente uns 5 anos, já a da direita sou eu com minha filha atualmente. Dois momentos distantes, mas que marcam minha vida. Ambos têm em comum o mesmo colégio, onde estudei um dia e hoje vejo minha filha estudando nele também!',
    author: 'Maikon Roque',
    image: img4,
  },
];

const DepoimentosSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duração da animação
      once: true, // Executa apenas uma vez
    });
  }, []);

  return (
    <section className={styles.testimonialsContainer}>
      <h2
        className={styles.title}
        data-aos="fade-up" // Título aparece subindo
      >
        O que dizem sobre nós
      </h2>
      <div className={styles.testimonialsGrid}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            testimonial={testimonial}
            aosDelay={index * 200} // Atraso escalonado
          />
        ))}
      </div>
    </section>
  );
};

const TestimonialCard = ({ testimonial, aosDelay }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_LENGTH = 150;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={styles.testimonialCard}
      data-aos="fade-up" // Animação de subida
      data-aos-delay={aosDelay} // Atraso para escalonamento
    >
      <img
        src={testimonial.image}
        alt={testimonial.author}
        className={styles.testimonialImage}
      />
      <p className={styles.testimonialText}>
        {isExpanded ? testimonial.text : `${testimonial.text.substring(0, MAX_LENGTH)}...`}
        {testimonial.text.length > MAX_LENGTH && (
          <span className={styles.readMore} onClick={toggleReadMore}>
            {isExpanded ? " Ler menos" : " Ler mais"}
          </span>
        )}
      </p>
      <p className={styles.testimonialAuthor}>- {testimonial.author}</p>
    </div>
  );
};

export default DepoimentosSection;
