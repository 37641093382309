import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styles from './AdminHeader1Edit.module.css';
import HeaderService from '../../../services/Header1Service';
import { storage } from '../../../firebaseConfig';

const AdminHeader1Edit = ({ onUpdate, headerId, headerData, onClose }) => {
    const [titulo, setTitulo] = useState('');
    const [subTitulo, setSubTitulo] = useState('');
    const [imagemDesktop, setImagemDesktop] = useState('');
    const [imagemMobile, setImagemMobile] = useState('');
    const [desktopFile, setDesktopFile] = useState(null); // Arquivo de imagem desktop
    const [mobileFile, setMobileFile] = useState(null); // Arquivo de imagem mobile
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (headerData) {
            setTitulo(headerData.titulo || '');
            setSubTitulo(headerData.subTitulo || '');
            setImagemDesktop(headerData.imagemDesktop || '');
            setImagemMobile(headerData.imagemMobile || '');
        }
    }, [headerData]);

    const handleFileUpload = async (file, path) => {
        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                null,
                (error) => reject(error),
                async () => {
                    const url = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(url);
                }
            );
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setUploading(true);

        try {
            const updatedData = { titulo, subTitulo };

            // Upload de imagem desktop
            if (desktopFile) {
                updatedData.imagemDesktop = await handleFileUpload(
                    desktopFile,
                    `headers/${headerId}/desktop.jpg`
                );
            }

            // Upload de imagem mobile
            if (mobileFile) {
                updatedData.imagemMobile = await handleFileUpload(
                    mobileFile,
                    `headers/${headerId}/mobile.jpg`
                );
            }

            await HeaderService.update(headerId, updatedData);
            onUpdate();
            onClose();
        } catch (error) {
            console.error('Erro ao atualizar header:', error);
        } finally {
            setUploading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <input
                type="text"
                placeholder="Título"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="text"
                placeholder="Subtítulo"
                value={subTitulo}
                onChange={(e) => setSubTitulo(e.target.value)}
                className={styles.input}
                required
            />
            <div className={styles.fileUpload}>
                <label>
                    Imagem Desktop:
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setDesktopFile(e.target.files[0])}
                        required={!imagemDesktop}
                    />
                </label>
                {imagemDesktop && (
                    <img
                        src={imagemDesktop}
                        alt="Preview Desktop"
                        className={styles.previewImage}
                    />
                )}
            </div>
            <div className={styles.fileUpload}>
                <label>
                    Imagem Mobile:
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setMobileFile(e.target.files[0])}
                        required={!imagemMobile}
                    />
                </label>
                {imagemMobile && (
                    <img
                        src={imagemMobile}
                        alt="Preview Mobile"
                        className={styles.previewImage}
                    />
                )}
            </div>
            <p>*Desktop: 1920x1080 ou maior.</p>
            <p>*Mobile: 768x1024.</p>
            {uploading ? (
                <p className={styles.uploadingText}>Fazendo upload...</p>
            ) : (
                <button type="submit" className={styles.button}>
                    Salvar Alterações
                </button>
            )}
        </form>
    );
};

export default AdminHeader1Edit;
