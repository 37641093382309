import React, { useState } from 'react';
import AdminNoticias from './Noticias/AdminNoticias';
import AdminProjetos from './Projetos/AdminProjetos';
import styles from './AdminStructure.module.css';
import AdminHeader1 from './Header1/AdminHeader1';
import AdminHeader2 from './Header2/AdminHeader2';

const AdminStructure = () => {
    const [content, setContent] = useState(<AdminNoticias />);
    const [sidebarOpen, setSidebarOpen] = useState(true); // Estado para abrir/fechar a barra lateral

    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

    const showNoticias = () => setContent(<AdminNoticias />);
    const showProjetos = () => setContent(<AdminProjetos />);
    const showHeader1 = () => setContent(<AdminHeader1 />);
    const showHeader2 = () => setContent(<AdminHeader2 />);

    return (
        <div className={styles.adminContainer}>
            <button 
                className={styles.hamburgerButton} 
                onClick={toggleSidebar}
            >
                ☰
            </button>

            {sidebarOpen && (
                <aside className={styles.sidebar}>
                    <h2 className={styles.title}>Admin Panel</h2>
                    <nav className={styles.nav}>
                        <ul>
                            <li>
                                <button onClick={showNoticias} className={`${styles.navButton} ${styles.iconNoticias}`}>Noticias</button>
                            </li>
                            <li>
                                <button onClick={showProjetos} className={`${styles.navButton} ${styles.iconProjetos}`}>Projetos</button>
                            </li>
                            <li>
                                <button onClick={showHeader1} className={`${styles.navButton} ${styles.iconNoticias}`}>Banner 1</button>
                            </li>
                            <li>
                                <button onClick={showHeader2} className={`${styles.navButton} ${styles.iconProjetos}`}>Banner 2</button>
                            </li>
                        </ul>
                    </nav>
                </aside>
            )}

            <main className={styles.contentArea}>
                {content}
            </main>
        </div>
    );
};

export default AdminStructure;
