import React from 'react';
import styles from './EnsinoInfantil.module.css';
import CursosSobreSection from '../../components/pagesComponents/Cursos/CursosSobreSection';
import TituloComponent from '../../components/Titulo/TituloComponent';
import img from '../../assets/Imagem Cursos/file (6).jpg'

const EnsinoInfantil = () => {
    const title = "Educação Infantil - Colégio Alternativo Colaço";

    const description = [
        [
            { text: "No ", bold: false },
            { text: "Colégio Alternativo Colaço", bold: true },
            { text: ", a Educação Infantil é um espaço onde as crianças vivenciam ", bold: false },
            { text: "descobertas e aprendizados", bold: true },
            { text: ", desenvolvendo sua autonomia e criatividade.", bold: false }
        ],
        [
            { text: "As atividades são planejadas para atender às ", bold: false },
            { text: "necessidades básicas", bold: true },
            { text: " da infância e incluem jogos, brincadeiras, contação de histórias, projetos interativos e atividades artísticas. Nossa proposta pedagógica está alinhada à ", bold: false },
            { text: "BNCC (Base Nacional Comum Curricular)", bold: true },
            { text: ", proporcionando um desenvolvimento integral.", bold: false }
        ],
        [
            { text: "No Infantil, priorizamos o ", bold: false },
            { text: "acolhimento e a construção de vínculos", bold: true },
            { text: ", valorizando a interação com os colegas e o meio ambiente, criando um ambiente seguro e estimulante para as crianças.", bold: false }
        ]
    ];

    const imageUrl = img; // Substituir por uma imagem real da Educação Infantil

    const pedagogicalResources = [
        "Sala de Leitura Infantil",
        "Área Sensorial",
        "Atividades Lúdicas e Sensorial",
        "Brinquedoteca",
        "Projetos Interativos",
        "Contação de Histórias",
        "Parque Infantil",
        "Atividades de Movimento e Psicomotricidade"
    ];

    const extraActivities = [
        "Projeto Alimentação Saudável",
        "Projeto Família Presente",
        "Semana Literária Infantil",
        "Datas Comemorativas Temáticas",
        "Eventos Lúdicos e Recreativos",
        "Projeto de Habilidades Socioemocionais"
    ];

    return (
        <>
            <TituloComponent name="Educação Infantil" />
            <CursosSobreSection
                title={title}
                description={description}
                imageUrl={imageUrl}
                pedagogicalResources={pedagogicalResources}
                extraActivities={extraActivities}
            />
        </>
    );
};

export default EnsinoInfantil;
