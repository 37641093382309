import React from 'react';
import styles from './CursosSobreSection.module.css';

const CursosSobreSection = ({ 
    title, 
    description, 
    imageUrl, 
    pedagogicalResources, 
    extraActivities 
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.title}>{title}</h2>
                <div className={styles.line}></div>
            </div>
            <div className={styles.content}>
                <div className={styles.textSection}>
                    {description.map((item, index) => (
                        <p key={index}>
                            {item.map((part, partIndex) =>
                                part.bold ? (
                                    <strong key={partIndex}>{part.text}</strong>
                                ) : (
                                    <span key={partIndex}>{part.text}</span>
                                )
                            )}
                        </p>
                    ))}
                </div>
                <div className={styles.imageSection}>
                    <img
                        src={imageUrl || "https://via.placeholder.com/400x250"}
                        alt="Imagem ilustrativa"
                        className={styles.image}
                    />
                </div>
            </div>
            <div className={styles.lists}>
                <div className={styles.listSection}>
                    <h3 className={styles.listTitle}>Recursos Pedagógicos</h3>
                    <ul className={styles.list}>
                        {pedagogicalResources.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
                <div className={styles.listSection}>
                    <h3 className={styles.listTitle}>Atividades Extras</h3>
                    <ul className={styles.list}>
                        {extraActivities.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CursosSobreSection;
