import React, { useEffect, useState } from 'react';
import styles from './Noticias.module.css';
import { getTodasUltimasNoticias } from '../services/NoticiasService';
import { FaImage } from "react-icons/fa6";
import TituloComponent from '../components/Titulo/TituloComponent';
import { useNavigate } from 'react-router-dom';

const Noticias = () => {
    const [noticias, setNoticias] = useState([]);
    const [verMais, setVerMais] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        // Rola para o topo ao carregar a página
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Suave
        });

        // Busca as notícias
        const fetchNoticias = async () => {
            const response = await getTodasUltimasNoticias();
            setNoticias(response);
        };
        fetchNoticias();
    }, []);

    const toggleVerMais = (id) => {
        setVerMais((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    return (
        <div className={styles.noticiasContainer}>
            <TituloComponent name="Notícias" />
            <div className={styles.noticiasLista}>
                {noticias.map((noticia) => (
                    <div key={noticia._id} className={styles.noticiaItem}>
                        {noticia.imagem && noticia.imagem.trim() !== "" ? (
                            <img src={noticia.imagem} alt={noticia.titulo} className={styles.noticiaImagem} />
                        ) : (
                            <div className={styles.noticiaImagemPlaceholder}>
                                <FaImage className={styles.iconPlaceholder} />
                            </div>
                        )}
                        <h2 className={styles.noticiaTitulo}>{noticia.titulo}</h2>
                        <p className={styles.noticiaData}>
                            <strong>Data:</strong> {new Date(noticia.data).toLocaleDateString()}
                        </p>

                        {/* Descrição com "Ler mais" */}
                        <p className={styles.noticiaDescricao}>
                            <strong>Descrição:</strong>{" "}
                            {verMais[noticia._id]
                                ? noticia.textoDescription
                                : `${noticia.textoDescription.substring(0, 40)}...`}
                            {noticia.textoDescription.length > 40 && (
                                <span
                                    className={styles.readMore}
                                    onClick={() => toggleVerMais(noticia._id)}
                                >
                                    {verMais[noticia._id] ? " Ler menos" : " Ler mais"}
                                </span>
                            )}
                        </p>

                        {/* Botão Saiba mais */}
                        <button
                            onClick={() => navigate("/noticia/" + noticia._id)}
                            className={styles.verMaisButton}
                        >
                            Saiba mais
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Noticias;
