import React, { useEffect, useState } from 'react';
import styles from './AdminProjetos.module.css';
import { getProjetos, deleteProjeto } from '../../../services/projetoService';
import PopUpAdminComponent from '../../PopUpAdmin/PopUpAdminComponent';
import AdminCreateProjetos from './AdminCreateProjetos';
import AdminEditProjetos from './AdminEditProjetos';

const AdminProjetos = () => {
    const [projetos, setProjetos] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [selectedProjeto, setSelectedProjeto] = useState(null);

    useEffect(() => {
        fetchProjetos();
    }, []);

    const fetchProjetos = async () => {
        try {
            const data = await getProjetos();
            setProjetos(data);
        } catch (error) {
            console.error("Erro ao buscar projetos:", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteProjeto(id);
            fetchProjetos();
        } catch (error) {
            console.error("Erro ao deletar projeto:", error);
        }
    };

    const openCreatePopup = () => setIsCreateOpen(true);
    const closeCreatePopup = () => setIsCreateOpen(false);

    const openEditPopup = (projeto) => {
        setSelectedProjeto(projeto);
        setIsEditOpen(true);
    };
    const closeEditPopup = () => {
        setSelectedProjeto(null);
        setIsEditOpen(false);
    };

    const filteredProjetos = projetos.filter((projeto) =>
        projeto.titulo.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h2>Administração de Projetos</h2>
                <div className={styles.actionsHeader}>
                    <input
                        type="text"
                        placeholder="Pesquisar projetos..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={styles.searchInput}
                    />
                    <button onClick={openCreatePopup} className={styles.createButton}>+ Novo Projeto</button>
                </div>
            </header>

            {isCreateOpen && (
                <PopUpAdminComponent title="Criar Projeto"  onClose={closeCreatePopup}>
                    <AdminCreateProjetos onCreate={fetchProjetos} onClose={closeCreatePopup} />
                </PopUpAdminComponent>
            )}

            {isEditOpen && selectedProjeto && (
                <PopUpAdminComponent title="Editar Projeto"  onClose={closeEditPopup}>
                    <AdminEditProjetos 
                        projeto={selectedProjeto} 
                        onEdit={fetchProjetos} 
                        onClose={closeEditPopup} 
                    />
                </PopUpAdminComponent>
            )}

            <div className={styles.projetosList}>
                {filteredProjetos.map((projeto) => (
                    <div key={projeto._id} className={styles.projetoItem}>
                        <div className={styles.projetoContent}>
                            <h3 className={styles.projetoTitle}>{projeto.titulo}</h3>
                            <p className={styles.projetoDescription}>{projeto.textoDescription}</p>
                            <small className={styles.projetoDate}>{new Date(projeto.data).toLocaleDateString()}</small>
                        </div>
                        {projeto.imagem && <img src={projeto.imagem} alt="Projeto" className={styles.projetoImagem} />}
                        <div className={styles.actions}>
                            <button onClick={() => openEditPopup(projeto)} className={styles.editButton}>Editar</button>
                            <button onClick={() => handleDelete(projeto._id)} className={styles.deleteButton}>Excluir</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminProjetos;
