import React, { useState } from 'react';
import styles from './AdminCreateProjetos.module.css';
import { createProjeto } from '../../../services/projetoService';
import { storage } from '../../../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const AdminCreateProjetos = ({ onCreate, onClose }) => {
    const [titulo, setTitulo] = useState('');
    const [subtitulo, setSubtitulo] = useState('');
    const [data, setData] = useState('');
    const [textoDescription, setTextoDescription] = useState('');
    const [texto, setTexto] = useState('');
    const [imagem, setImagem] = useState('');
    const [file, setFile] = useState(null); // Para armazenar o arquivo selecionado
    const [uploading, setUploading] = useState(false); // Estado de carregamento do upload

    const handleFileUpload = async (file) => {
        const storageRef = ref(storage, `projetos/${titulo.replace(/\s+/g, '_')}_${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                null,
                (error) => reject(error),
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(downloadURL);
                }
            );
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUploading(true); // Inicia o estado de upload

        try {
            let imagemUrl = imagem;

            if (file) {
                imagemUrl = await handleFileUpload(file); // Faz o upload e obtém a URL
            }

            await createProjeto({
                titulo,
                subtitulo,
                data,
                textoDescription,
                texto,
                imagem: imagemUrl,
            });

            onCreate(); // Atualiza a lista de projetos no componente pai
            onClose(); // Fecha o modal de criação
        } catch (error) {
            console.error('Erro ao criar projeto:', error);
        } finally {
            setUploading(false); // Finaliza o estado de upload
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <input
                type="text"
                placeholder="Título"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="text"
                placeholder="Subtítulo"
                value={subtitulo}
                onChange={(e) => setSubtitulo(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="date"
                placeholder="Data"
                value={data}
                onChange={(e) => setData(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="text"
                placeholder="Descrição"
                value={textoDescription}
                onChange={(e) => setTextoDescription(e.target.value)}
                className={styles.input}
                required
            />
            <textarea
                placeholder="Texto completo"
                value={texto}
                onChange={(e) => setTexto(e.target.value)}
                className={styles.textarea}
                required
            ></textarea>
            <div className={styles.fileUpload}>
                <label>
                    Anexar Imagem:
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setFile(e.target.files[0])}
                        required
                    />
                </label>
                {file && (
                    <img
                        src={URL.createObjectURL(file)}
                        alt="Preview"
                        className={styles.previewImage}
                    />
                )}
            </div>
            {uploading ? (
                <p className={styles.uploadingText}>Fazendo upload...</p>
            ) : (
                <button type="submit" className={styles.button}>
                    Criar Projeto
                </button>
            )}
        </form>
    );
};

export default AdminCreateProjetos;
