import axios from 'axios';
import { useSelector } from 'react-redux';

const apiAxios = axios.create({
    baseURL: "https://site-backend-nu.vercel.app/",
    //baseURL: "http://localhost:5000",
});

const ApiAxiosInterceptor = () => {
    const tokenData = useSelector((state) => state.user?.userInfo?.data?.token);
    const token = useSelector((state) => state.user?.userInfo?.token);

    const finalToken = tokenData || token;

    apiAxios.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers.Authorization = `Bearer ${finalToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    return null;
};

export default apiAxios;
export { ApiAxiosInterceptor };