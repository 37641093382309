import React, { useEffect } from "react";
import AOS from "aos"; // Importa o AOS
import "aos/dist/aos.css"; // Importa os estilos do AOS
import styles from "./BeneficiosSection.module.css";
import {
  FaHeartbeat,
  FaUtensils,
  FaShieldAlt,
  FaVideo,
  FaChalkboardTeacher,
  FaChild,
  FaBook,
  FaPaintBrush,
} from "react-icons/fa";

const beneficiosData = [
  {
    title: "Apoio Psicológico",
    description: "Atendimento psicológico para apoiar o desenvolvimento emocional dos alunos.",
    icon: <FaHeartbeat />,
  },
  {
    title: "Apoio Nutricional",
    description: "Orientação nutricional para promover hábitos alimentares saudáveis.",
    icon: <FaUtensils />,
  },
  {
    title: "Serviço de Emergência Médica",
    description: "Equipe médica à disposição para emergências e primeiros socorros.",
    icon: <FaShieldAlt />,
  },
  {
    title: "42 Câmeras de Monitoramento",
    description: "Monitoramento de segurança em todas as áreas da escola.",
    icon: <FaVideo />,
  },
  {
    title: "Atividades Extracurriculares",
    description: "Diversas atividades como recreação aquática, música e teatro.",
    icon: <FaChild />,
  },
  {
    title: "Aulas de Inglês",
    description: "Ensino bilíngue com foco no aprendizado de inglês desde a educação infantil.",
    icon: <FaBook />,
  },
  {
    title: "Escola Olímpica",
    description: "Preparação e incentivo para olimpíadas escolares de conhecimento, como Canguru de Matemática e outras competições acadêmicas.",
    icon: <FaChalkboardTeacher />,
  },
  {
    title: "Área Sensorial",
    description: "Espaço dedicado ao estímulo sensorial com um painel sensorial interativo.",
    icon: <FaPaintBrush />,
  },
];

const BeneficiosSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duração das animações
      once: true, // Executa a animação apenas uma vez
    });
  }, []);

  return (
    <section className={styles.beneficiosContainer}>
      <div className={styles.wave}></div> {/* Onda animada */}
      <h2
        className={styles.title}
        data-aos="fade-down" // Título aparece de cima para baixo
      >
        Nossos Diferenciais
      </h2>
      <div className={styles.beneficiosGrid}>
        {beneficiosData.map((beneficio, index) => (
          <div
            key={index}
            className={styles.beneficioCard}
            data-aos="fade-up" // Animação de baixo para cima
            data-aos-delay={index * 100} // Atraso escalonado
          >
            <div className={styles.icon}>{beneficio.icon}</div>
            <h3 className={styles.beneficioTitle}>{beneficio.title}</h3>
            <p className={styles.beneficioDescription}>{beneficio.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BeneficiosSection;
