// src/services/projetoService.js
import apiAxios from '../api/apiAxios';

const BASE_URL = '/projetos'; // Defina a rota base

// Função para obter todos os projetos
export const getProjetos = async () => {
    const response = await apiAxios.get(BASE_URL);
    return response.data;
};

// Função para criar um novo projeto
export const createProjeto = async (projetoData) => {
    const response = await apiAxios.post(BASE_URL, projetoData);
    return response.data;
};

// Função para obter um projeto por ID
export const getProjetoById = async (id) => {
    const response = await apiAxios.get(`${BASE_URL}/${id}`);
    return response.data;
};

export const getProjetoByTitulo = async (titulo) => {
    const encodedTitulo = encodeURIComponent(titulo); // Codifica o título para lidar com espaços e caracteres especiais
    const response = await apiAxios.get(`${BASE_URL}/titulo/${encodedTitulo}`);
    return response.data;
};

// Função para atualizar um projeto por ID
export const updateProjeto = async (id, projetoData) => {
    const response = await apiAxios.put(`${BASE_URL}/${id}`, projetoData);
    return response.data;
};

// Função para deletar um projeto por ID
export const deleteProjeto = async (id) => {
    const response = await apiAxios.delete(`${BASE_URL}/${id}`);
    return response.data;
};
