// AuthContext.js
import React, { createContext, useEffect, useState } from 'react';
import { auth } from './firebaseConfig'; // Certifique-se de que o caminho está correto
import { onAuthStateChanged } from 'firebase/auth';
import styles from './AuthContext.module.css'
// Cria o contexto de autenticação
export const AuthContext = createContext();

// Cria o provedor de autenticação
export function AuthProvider({ children }) {
  const [usuarioAtual, setUsuarioAtual] = useState(null);
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUsuarioAtual(user);
      setCarregando(false);
    });

    // Limpa o observador quando o componente é desmontado
    return () => unsubscribe();
  }, []);

  if (carregando) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}></div>
      </div>
    )
  }

  return (
    <AuthContext.Provider value={{ usuarioAtual }}>
      {children}
    </AuthContext.Provider>
  );
}
