import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './NoticiasDetail.module.css';
import { getNoticiaByTitulo } from '../services/NoticiasService';
import { FaImage } from "react-icons/fa6";

const NoticiasDetail = () => {
    const { titulo } = useParams();
    const [noticia, setNoticia] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNoticia = async () => {
            try {
                const response = await getNoticiaByTitulo(titulo);
                setNoticia(response);
            } catch (error) {
                console.error("Erro ao buscar a notícia:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchNoticia();
    }, [titulo]);

    // Função para converter um link de YouTube em um link de "embed"
    const convertYoutubeLink = (url) => {
        const youtubeMatch = url.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=))([^?&]+)/);
        return youtubeMatch ? `https://www.youtube.com/embed/${youtubeMatch[1]}` : url;
    };

    if (loading) return <div className={styles.loading}>Carregando...</div>;
    if (!noticia) return <div className={styles.notFound}>Notícia não encontrada.</div>;

    return (
        <div className={styles.noticiaFull}>
            <div className={styles.noticiaContainer}>
                <h1 className={styles.noticiaTitulo}>{noticia.titulo}</h1>
                <p className={styles.noticiaData}>
                    <strong>Data:</strong> {new Date(noticia.data).toLocaleDateString()}
                </p>
                <div className={styles.noticiaImagemContainer}>
                    {noticia.imagem ? (
                        <img src={noticia.imagem} alt={noticia.titulo} className={styles.noticiaImagem} />
                    ) : (
                        <div className={styles.noticiaImagemPlaceholder}>
                            <FaImage className={styles.iconPlaceholder} />
                        </div>
                    )}
                </div>
                <h3 className={styles.noticiaSubtitulo}>{noticia.subtitulo}</h3>
                <p className={styles.noticiaDescricao}><strong>Descrição:</strong> {noticia.textoDescription}</p>
                <div className={styles.noticiaTexto}>{noticia.texto}</div>
                
                {noticia.video && (
                    <div className={styles.noticiaVideoContainer}>
                        {noticia.video.includes("youtube.com") || noticia.video.includes("youtu.be") ? (
                            <iframe
                                src={convertYoutubeLink(noticia.video)}
                                title="Vídeo relacionado"
                                className={styles.noticiaVideo}
                                allowFullScreen
                            />
                        ) : (
                            <a href={noticia.video} target="_blank" rel="noopener noreferrer" className={styles.videoLink}>
                                Assista ao vídeo
                            </a>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NoticiasDetail;
