// src/components/PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

function PrivateRoute({ children }) {
  const { usuarioAtual, carregando } = useContext(AuthContext);

  if (carregando) {
    return <p>Carregando.......</p>; // Ou um spinner de carregamento
  }

  return usuarioAtual ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
