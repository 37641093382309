import React from 'react';
import styles from './Hino.module.css';
import TituloComponent from '../components/Titulo/TituloComponent';
import hinoMp3 from '../assets/Hino.6f0e49ef362ac11b3004.mp3';

const Hino = () => {
    return (
        <div className={styles.hinoContainer}>
            <TituloComponent name="Hino do Colégio" />
            <div className={styles.content}>
                <section className={styles.section}>
                    <div className={styles.lyrics}>
                        <p>Nosso objetivo é educação,</p>
                        <p>Fazendo da vida uma missão</p>
                        <p>Vontade de sempre aprender,</p>
                        <p>A construir uma justa nação.</p>
                        <p className={styles.refrain}>
                            <strong>Sonhando juntos, crescendo juntos,</strong><br />
                            <strong>Para engrandecer a Pátria mãe!</strong><br />
                            <strong>Ó Pátria mãe (bis)</strong>
                        </p>
                        <p>Futuros guerreiros somos cidadãos.</p>
                        <p>Carregando no peito uma missão,</p>
                        <p>Aprendendo que a vida é sempre aprender,</p>
                        <p>É ensinar e propagar nosso saber.</p>
                        <p>Abrindo espaços sempre ricos,</p>
                        <p>Aprendendo e ensinando a viver,</p>
                        <p>Com direitos e deveres aprendidos,</p>
                        <p>Se conquista o direito de vencer.</p>
                        <p className={styles.refrain}>
                            <strong>Através do saber abrem-se as portas,</strong><br />
                            <strong>Com liberdade e participação,</strong><br />
                            <strong>Alternativo, nossa opção,</strong><br />
                            <strong>Para promover a educação (bis)</strong>
                        </p>
                    </div>
                    <div className={styles.authors}>
                        <p><strong>Autoras:</strong> Rainete Colaço & Soraia Colaço.</p>
                        <p><strong>Música:</strong> Adriano Cajula.</p>
                        <p><strong>Voz:</strong> Michele Colaço.</p>
                    </div>
                </section>
                <section className={styles.audioSection}>
                    <audio controls>
                        <source src={hinoMp3} type="audio/mpeg" />
                        Seu navegador não suporta o elemento de áudio.
                    </audio>
                </section>
            </div>
        </div>
    );
};

export default Hino;
