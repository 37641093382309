import React from 'react';
import styles from './TituloComponent.module.css';

const TituloComponent = ({name}) => {
    return (
        <div className={styles.tituloContainer}>
            <h1 className={styles.titulo}>{name}</h1>
            <hr className={styles.underline} />
        </div>
    );
}

export default TituloComponent;
