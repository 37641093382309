import React, { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import HeaderService from '../../../services/Header1Service';
import styles from './AdminHeader1Create.module.css';
import { storage } from '../../../firebaseConfig';

const AdminHeader1Create = ({ onCreate }) => {
    const [titulo, setTitulo] = useState('');
    const [subTitulo, setSubTitulo] = useState('');
    const [desktopFile, setDesktopFile] = useState(null); // Arquivo de imagem desktop
    const [mobileFile, setMobileFile] = useState(null); // Arquivo de imagem mobile
    const [desktopPreview, setDesktopPreview] = useState(''); // Prévia da imagem desktop
    const [mobilePreview, setMobilePreview] = useState(''); // Prévia da imagem mobile
    const [uploading, setUploading] = useState(false); // Estado para mostrar se está fazendo upload

    const handleFileUpload = async (file, path) => {
        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                null,
                (error) => reject(error),
                async () => {
                    const url = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(url);
                }
            );
        });
    };

    const handleDesktopFileChange = (e) => {
        const file = e.target.files[0];
        setDesktopFile(file);

        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setDesktopPreview(previewUrl);
        }
    };

    const handleMobileFileChange = (e) => {
        const file = e.target.files[0];
        setMobileFile(file);

        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setMobilePreview(previewUrl);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setUploading(true); // Define o estado como "uploading"

        try {
            let imagemDesktop = '';
            let imagemMobile = '';

            // Upload da imagem desktop
            if (desktopFile) {
                imagemDesktop = await handleFileUpload(
                    desktopFile,
                    `headers/${titulo.replace(/\s+/g, '_')}_desktop.jpg`
                );
            }

            // Upload da imagem mobile
            if (mobileFile) {
                imagemMobile = await handleFileUpload(
                    mobileFile,
                    `headers/${titulo.replace(/\s+/g, '_')}_mobile.jpg`
                );
            }

            // Criação do header no backend
            await HeaderService.create({
                titulo,
                subTitulo,
                imagemDesktop,
                imagemMobile,
            });

            onCreate(); // Atualiza a lista no componente pai e fecha o modal
            resetForm(); // Reseta o formulário
        } catch (error) {
            console.error('Erro ao criar header:', error);
        } finally {
            setUploading(false); // Finaliza o estado de upload
        }
    };

    const resetForm = () => {
        setTitulo('');
        setSubTitulo('');
        setDesktopFile(null);
        setMobileFile(null);
        setDesktopPreview('');
        setMobilePreview('');
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <input
                type="text"
                placeholder="Título"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="text"
                placeholder="Subtítulo"
                value={subTitulo}
                onChange={(e) => setSubTitulo(e.target.value)}
                className={styles.input}
                required
            />
            <div className={styles.fileUpload}>
                <label>
                    Imagem Desktop:
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleDesktopFileChange}
                        required
                    />
                </label>
                {desktopPreview && (
                    <img
                        src={desktopPreview}
                        alt="Prévia Desktop"
                        className={styles.previewImage}
                    />
                )}
            </div>
            <div className={styles.fileUpload}>
                <label>
                    Imagem Mobile:
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleMobileFileChange}
                        required
                    />
                </label>
                {mobilePreview && (
                    <img
                        src={mobilePreview}
                        alt="Prévia Mobile"
                        className={styles.previewImage}
                    />
                )}
            </div>
            <p>*Desktop: 1920x1080 ou maior.</p>
            <p>*Mobile: 768x1024.</p>
            {uploading ? (
                <p className={styles.uploadingText}>Fazendo upload...</p>
            ) : (
                <button type="submit" className={styles.button}>Criar Banner</button>
            )}
        </form>
    );
};

export default AdminHeader1Create;
