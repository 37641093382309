import React, { useState, useEffect } from 'react';
import styles from './AdminEditNoticias.module.css';
import { updateNoticia } from '../../../services/NoticiasService';
import { storage } from '../../../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const AdminEditNoticias = ({ noticiaId, onUpdate, noticiaData }) => {
    const [titulo, setTitulo] = useState(noticiaData?.titulo || '');
    const [data, setData] = useState(noticiaData?.data ? noticiaData.data.slice(0, 10) : '');
    const [textoDescription, setTextoDescription] = useState(noticiaData?.textoDescription || '');
    const [texto, setTexto] = useState(noticiaData?.texto || '');
    const [imagem, setImagem] = useState(noticiaData?.imagem || '');
    const [video, setVideo] = useState(noticiaData?.video || '');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if (noticiaData) {
            setTitulo(noticiaData.titulo || '');
            setData(noticiaData.data ? noticiaData.data.slice(0, 10) : '');
            setTextoDescription(noticiaData.textoDescription || '');
            setTexto(noticiaData.texto || '');
            setImagem(noticiaData.imagem || '');
            setVideo(noticiaData.video || '');
        }
    }, [noticiaData]);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setIsUploading(true); // Inicia o estado de upload
        const storageRef = ref(storage, `noticias/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error('Erro ao fazer upload da imagem:', error);
                setIsUploading(false); // Finaliza o estado de upload em caso de erro
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                setImagem(downloadURL); // Atualiza o estado da imagem com o URL
                setIsUploading(false); // Finaliza o estado de upload
            }
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isUploading) {
            alert('Aguarde o término do upload da imagem.');
            return;
        }

        if (titulo && data && textoDescription && texto) {
            await updateNoticia(noticiaId, {
                titulo,
                data,
                textoDescription,
                texto,
                imagem, // URL da imagem do estado
                video,
            });
            onUpdate(); // Atualiza a lista de notícias e fecha o popup
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <input
                type="text"
                placeholder="Título"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="date"
                placeholder="Data"
                value={data}
                onChange={(e) => setData(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="text"
                placeholder="Descrição"
                value={textoDescription}
                onChange={(e) => setTextoDescription(e.target.value)}
                className={styles.input}
                required
            />
            <textarea
                placeholder="Texto completo"
                value={texto}
                onChange={(e) => setTexto(e.target.value)}
                className={styles.textarea}
                required
            ></textarea>
            <input
                type="file"
                onChange={handleImageUpload}
                className={styles.input}
            />
            <p>Tamanho ideal: *1280x720px</p>
            {uploadProgress > 0 && uploadProgress < 100 && (
                <p>Progresso do upload: {Math.round(uploadProgress)}%</p>
            )}
            {imagem && <img src={imagem} alt="Imagem da notícia" className={styles.preview} />}
            <input
                type="text"
                placeholder="URL do Vídeo"
                value={video}
                onChange={(e) => setVideo(e.target.value)}
                className={styles.input}
            />
            <button type="submit" className={styles.button} disabled={isUploading}>
                {isUploading ? 'Aguarde...' : 'Atualizar Notícia'}
            </button>
        </form>
    );
};

export default AdminEditNoticias;
