import React from 'react';
import styles from './EnsinoFundamental.module.css';
import CursosSobreSection from '../../components/pagesComponents/Cursos/CursosSobreSection';
import TituloComponent from '../../components/Titulo/TituloComponent';
import img from '../../assets/Imagem Cursos/file (7).jpg'

const EnsinoFundamental = () => {
    const title = "Ensino Fundamental I - Colégio Alternativo Colaço";

    const description = [
        [
            { text: "No ", bold: false },
            { text: "Colégio Alternativo Colaço", bold: true },
            { text: ", o ", bold: false },
            { text: "Ensino Fundamental I", bold: true },
            { text: " é uma etapa essencial para a formação do conhecimento e do desenvolvimento das habilidades socioemocionais dos alunos.", bold: false }
        ],
        [
            { text: "Nossa proposta pedagógica está alinhada à ", bold: false },
            { text: "BNCC (Base Nacional Comum Curricular)", bold: true },
            { text: ", e busca promover uma aprendizagem significativa, onde os alunos desenvolvem competências como ", bold: false },
            { text: "leitura, escrita, raciocínio lógico", bold: true },
            { text: " e o pensamento crítico.", bold: false }
        ],
        [
            { text: "Valorizamos as ", bold: false },
            { text: "interações e o trabalho em equipe", bold: true },
            { text: ", criando um ambiente de respeito, acolhimento e colaboração, que contribui para o ", bold: false },
            { text: "crescimento integral", bold: true },
            { text: " dos nossos estudantes.", bold: false }
        ]
    ];

    const imageUrl = img; // Substituir por uma imagem real do Ensino Fundamental I

    const pedagogicalResources = [
        "Biblioteca",
        "Área Sensorial",
        "Aulas Interdisciplinares",
        "Projeto de Leitura e Escrita",
        "Aulas de Tecnologia e Inovação",
        "Atividades de Raciocínio Lógico"
    ];

    const extraActivities = [
        "Projeto de Educação Ambiental",
        "Feira de Ciências e Cultura",
        "Olimpíadas de Matemática e Língua Portuguesa",
        "Excursões e Aulas de Campo",
        "Datas Comemorativas Temáticas",
        "Semana Literária",
        "Feira das Profissões",
        "Feira de Iniciação Científica",
        "Apresentações Teatrais",
        "Projeto de Habilidades Socioemocionais"
    ];

    return (
        <>
            <TituloComponent name="Ensino Fundamental I" />
            <CursosSobreSection
                title={title}
                description={description}
                imageUrl={imageUrl}
                pedagogicalResources={pedagogicalResources}
                extraActivities={extraActivities}
            />
        </>
    );
};

export default EnsinoFundamental;
