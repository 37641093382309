import React, { useState, useEffect } from 'react';
import styles from './AdminHeader2.module.css';
import Header2Service from '../../../services/Header2Service';
import PopUpAdminComponent from '../../PopUpAdmin/PopUpAdminComponent';
import AdminHeader2Create from './AdminHeader2Create';
import AdminHeader2Edit from './AdminHeader2Edit';
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdLaptopChromebook } from "react-icons/md";

const AdminHeader2 = () => {
    const [banners, setBanners] = useState([]);
    const [editId, setEditId] = useState(null);
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [viewMode, setViewMode] = useState('desktop');
    const [bannerData, setBannerData] = useState(null);

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const data = await Header2Service.getAll();
            setBanners(data);
        } catch (error) {
            console.error('Erro ao buscar banners:', error);
        }
    };

    const handleCreateBanner = async (banner) => {
        try {
            await Header2Service.create(banner);
            fetchBanners();
            setIsCreatePopupOpen(false);
        } catch (error) {
            console.error('Erro ao criar banner:', error);
        }
    };

    const handleUpdateBanner = async (banner) => {
        try {
            await Header2Service.update(editId, banner);
            fetchBanners();
            setEditId(null);
            setIsEditPopupOpen(false);
        } catch (error) {
            console.error('Erro ao atualizar banner:', error);
        }
    };

    const startEdit = (banner) => {
        setEditId(banner._id);
        setBannerData(banner);
        setIsEditPopupOpen(true);
    };

    const handleDeleteBanner = async (id) => {
        try {
            await Header2Service.delete(id);
            fetchBanners();
        } catch (error) {
            console.error('Erro ao deletar banner:', error);
        }
    };

    const filteredBanners = banners.filter((banner) =>
        banner.titulo?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h2>Gerenciar Banners</h2>
                <div className={styles.actionsHeader}>
                    <input
                        type="text"
                        placeholder="Buscar por título..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={styles.searchInput}
                    />
                    <button onClick={() => setIsCreatePopupOpen(true)} className={styles.addButton}>
                        + Novo Banner
                    </button>
                </div>
            </header>

            <div className={styles.viewToggle}>
                <button
                    className={`${styles.viewButton} ${viewMode === 'desktop' ? styles.active : ''}`}
                    onClick={() => setViewMode('desktop')}
                >
                    <MdLaptopChromebook size={24} />
                </button>
                <button
                    className={`${styles.viewButton} ${viewMode === 'mobile' ? styles.active : ''}`}
                    onClick={() => setViewMode('mobile')}
                >
                    <IoPhonePortraitOutline size={24} />
                </button>
            </div>

            {isCreatePopupOpen && (
                <PopUpAdminComponent title="Criar Banner" onClose={() => setIsCreatePopupOpen(false)}>
                    <AdminHeader2Create onCreate={handleCreateBanner} />
                </PopUpAdminComponent>
            )}

            {isEditPopupOpen && (
                <PopUpAdminComponent title="Editar Banner" onClose={() => setIsEditPopupOpen(false)}>
                    <AdminHeader2Edit
                        onUpdate={handleUpdateBanner}
                        bannerId={editId}
                        bannerData={bannerData}
                        onClose={() => setIsEditPopupOpen(false)}
                    />
                </PopUpAdminComponent>
            )}

            <div className={styles.bannersList}>
                {filteredBanners.map((banner) => (
                    <div key={banner._id} className={styles.bannerItem}>
                        <div className={`${styles.imageContainer} ${viewMode === 'desktop' ? styles.desktop : styles.mobile}`}>
                            {viewMode === 'desktop' && banner.imagemDesktop && (
                                <img
                                    src={banner.imagemDesktop}
                                    alt={`${banner.titulo} (Desktop)`}
                                    className={`${styles.imageDesktop} ${viewMode === 'desktop' ? styles.active : ''}`}
                                />
                            )}
                            {viewMode === 'mobile' && banner.imagemMobile && (
                                <img
                                    src={banner.imagemMobile}
                                    alt={`${banner.titulo} (Mobile)`}
                                    className={`${styles.imageMobile} ${viewMode === 'mobile' ? styles.active : ''}`}
                                />
                            )}
                        </div>
                        <div className={styles.bannerContent}>
                            <h3 className={styles.bannerTitle}>{banner.titulo}</h3>
                            <div className={styles.actions}>
                                <button onClick={() => startEdit(banner)} className={styles.editButton}>
                                    Editar
                                </button>
                                <button onClick={() => handleDeleteBanner(banner._id)} className={styles.deleteButton}>
                                    Excluir
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminHeader2;
