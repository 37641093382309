import React from 'react';
import styles from './SistemaTempoIntegral.module.css';
import TituloComponent from '../../components/Titulo/TituloComponent';
import CursosSobreSection from '../../components/pagesComponents/Cursos/CursosSobreSection';
import img from '../../assets/Imagem Cursos/file (8).jpg'

const SistemaTempoIntegral = () => {
    const title = "Sistema de Tempo Integral - Colégio Alternativo Colaço";

    const description = [
        [
            { text: "O ", bold: false },
            { text: "Sistema de Tempo Integral", bold: true },
            { text: " do ", bold: false },
            { text: "Colégio Alternativo Colaço", bold: true },
            { text: " é uma proposta inovadora que une educação de qualidade e cuidado integral com as crianças, oferecendo um ambiente seguro, acolhedor e enriquecedor.", bold: false }
        ],
        [
            { text: "Nosso objetivo é proporcionar uma ", bold: false },
            { text: "rotina equilibrada", bold: true },
            { text: " entre o aprendizado acadêmico, atividades lúdicas e momentos de lazer, fortalecendo o ", bold: false },
            { text: "desenvolvimento integral", bold: true },
            { text: " dos alunos.", bold: false }
        ],
        [
            { text: "As atividades do Tempo Integral incluem reforço escolar, projetos interativos, aulas de arte, música e esportes, além de momentos de convivência e recreação, sempre com acompanhamento pedagógico.", bold: false }
        ]
    ];

    const imageUrl = img; // Substituir por uma imagem real do sistema de tempo integral

    const pedagogicalResources = [
        "Reforço Escolar com Acompanhamento Pedagógico",
        "Aulas de Arte e Música",
        "Projetos de Leitura e Escrita",
        "Atividades Esportivas e Recreativas",
        "Alimentação Balanceada Supervisionada por Nutricionista"
    ];

    const extraActivities = [
        "Aulas de Educação Ambiental",
        "Oficinas de Teatro e Dança",
        "Campeonatos Esportivos Internos",
        "Sessões de Cinema Educativo",
        "Comemorações de Datas Temáticas",
        "Projeto de Habilidades Socioemocionais"
    ];

    return (
        <>
            <TituloComponent name="Sistema de Tempo Integral" />
            <CursosSobreSection
                title={title}
                description={description}
                imageUrl={imageUrl}
                pedagogicalResources={pedagogicalResources}
                extraActivities={extraActivities}
            />
        </>
    );
};

export default SistemaTempoIntegral;
