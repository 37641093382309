import React, { useState } from 'react';
import styles from './TrabalheConosco.module.css';
import emailjs from 'emailjs-com';
import { storage } from '../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import TituloComponent from '../components/Titulo/TituloComponent';

const TrabalheConosco = () => {
    const [file, setFile] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const { width, height } = useWindowSize();

    const handleFileChange = (e) => setFile(e.target.files[0]);

    const uploadToFirebase = async (file) => {
        const storageRef = ref(storage, `resumes/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        try {
            await new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    null,
                    (error) => reject(error),
                    () => resolve()
                );
            });
            const downloadURL = await getDownloadURL(storageRef);
            return downloadURL;
        } catch (error) {
            console.error('Erro ao fazer upload para o Firebase:', error);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let resumeLink = 'Nenhum currículo anexado';
            if (file) {
                resumeLink = await uploadToFirebase(file);
                if (!resumeLink) throw new Error('Falha no upload do currículo');
            }

            const emailData = {
                to_name: 'Colégio Alternativo Colaço',
                from_name: e.target.name.value,
                from_email: e.target._replyto.value,
                message: e.target.message.value,
                phone: e.target.phone.value,
                resume_url: resumeLink,
            };

            await emailjs.send('service_t9l8ign', 'template_z8pzv9e', emailData, 'Mu_S4P9Iunyuc0DuK');

            setShowConfetti(true);
            setSubmitted(true);
            window.scrollTo(0, 0);

            setTimeout(() => setShowConfetti(false), 10000);
        } catch (error) {
            console.error('Erro ao enviar o formulário:', error);
        }
    };

    if (submitted) {
        return (
            <div className={styles.container} style={{marginTop:"70px"}}>
                {showConfetti && <Confetti width={width} height={height} />}
                <div className={styles.successMessage}>
                    <h1>Obrigado!</h1>
                    <p>Seu formulário foi enviado com sucesso. 🎉 Entraremos em contato em breve.</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <TituloComponent name="Trabalhe Conosco" />
            <div className={styles.container}>
                <div className={styles.header}>
                    <h3>Entre em contato</h3>
                    <p>Preencha o formulário abaixo para se candidatar:</p>
                </div>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.inputGroup}>
                        <label htmlFor="name">Nome*</label>
                        <input
                            id="name"
                            className={styles.input}
                            type="text"
                            name="name"
                            required
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="_replyto">Email*</label>
                        <input
                            id="_replyto"
                            className={styles.input}
                            type="email"
                            name="_replyto"
                            required
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="phone">Telefone</label>
                        <input
                            id="phone"
                            className={styles.input}
                            type="tel"
                            name="phone"
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="message">Mensagem*</label>
                        <textarea
                            id="message"
                            className={styles.textarea}
                            name="message"
                            required
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="resume">Anexar Currículo</label>
                        <input
                            id="resume"
                            type="file"
                            onChange={handleFileChange}
                            accept=".pdf,.doc,.docx"
                            className={styles.input}
                        />
                    </div>
                    <button type="submit" className={styles.button}>
                        Enviar
                    </button>
                </form>
            </div>
        </>
    );
};

export default TrabalheConosco;
