import React from 'react';
import styles from './Footer.module.css';
import { FaWhatsapp, FaPhoneAlt, FaEnvelope, FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa';
import { IoIosArrowUp } from "react-icons/io";

const Footer = () => {
    // Função para rolar para o topo
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <footer className={styles.footer}>
            <div className={styles.locationSection}>
                <h3>ONDE ESTAMOS</h3>
                <p>Rua Viriato Ribeiro, 724, Bela Vista<br />Fortaleza, CE, CEP 60442-642</p>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.2715148076604!2d-38.56298282633185!3d-3.7509436432845216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c7495ce2ec0001%3A0xe91e32e2e761214b!2sR.%20Viriato%20Ribeiro%2C%20724%20-%20Bela%20Vista%2C%20Fortaleza%20-%20CE%2C%2060442-642!5e0!3m2!1spt-PT!2sbr!4v1730498175842!5m2!1spt-PT!2sbr"
                    width="100%"
                    height="300"
                    style={{ border: '0' }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className={styles.map}
                ></iframe>
            </div>

            {/* Botões de Contato */}
            <div className={styles.contactButtons}>
                <a href="https://api.whatsapp.com/send/?phone=5585988068633" className={styles.whatsappButton}>
                    <FaWhatsapp />&nbsp;WhatsApp
                </a>
                <a href="tel:+5585988068633" className={styles.phoneButton}>
                    <FaPhoneAlt />&nbsp;Telefone
                </a>
                <a href="mailto:colegioalternativocolaco@gmail.com" className={styles.emailButton}>
                    <FaEnvelope />&nbsp;Email
                </a>
            </div>

            {/* Redes Sociais */}
            <div className={styles.socialMedia}>
                <a href="https://www.instagram.com/colegioalternativocolaco/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                <a href="https://www.facebook.com/colegioalternativocolaco?locale=pt_BR" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                <a href="https://www.youtube.com/channel/UCNFCZP-7hl0TSZAybOwhYvw" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
            </div>

            {/* Botão para Voltar ao Topo */}
            <div className={styles.returnTop} onClick={scrollToTop}>
                <IoIosArrowUp className={styles.arrowIcon} />
            </div>

            {/* Informações da Instituição */}
            <div className={styles.institutionInfo}>
                <h3>Colégio Alternativo Colaço - Fortaleza</h3>
                <p>&copy; Colégio Alternativo Colaço - 2025</p>
                <p>CNPJ: 02.368.851.0001-17</p>
                <p>Todos os Direitos Reservados</p>
                <p>Desenvolvido por <a href="https://educacaoalternativa360.com.br">Educação Alternativa 360</a></p>
            </div>
        </footer>
    );
};

export default Footer;
