// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import { Header } from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CookiesConsent from './components/Cookies/CookiesConsent';
import FloatWhatzapButton from './components/FloatWhatzapButton/FloatWhatzapButton';
import { AuthProvider } from './AuthContext'; 
import Login from './pages/Login';
import AdminHome from './pages/AdminPages/AdminHome';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Projetos from './pages/Projetos';
import Noticias from './pages/Noticias';
import ProjetosDetail from './pages/ProjetosDetail';
import NoticiasDetail from './pages/NoticiasDetail';
import Historia from './pages/Historia';
import PropostaPedagogica from './pages/PropostaPedagogica';
import Hino from './pages/Hino';
import EnsinoFundamental from './pages/Cursos/EnsinoFundamental';
import EnsinoInfantil from './pages/Cursos/EnsinoInfantil';
import SistemaTempoIntegral from './pages/Cursos/SistemaTempoIntegral';
import Contato from './pages/Contato';
import Matriculas from './pages/Matriculas';
import TrabalheConosco from './pages/TrabalheConosco';

function AppContent() {
  const location = useLocation();
  const isAdminRoute = location.pathname === '/admin';

  return (
    <>
      {!isAdminRoute && <Header />}
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/todos-projetos" element={<Projetos />} />
          <Route path="/projeto/:titulo" element={<ProjetosDetail />} />
          <Route path="/todos-noticias" element={<Noticias />} />
          <Route path="/noticia/:titulo" element={<NoticiasDetail />} />
          <Route path="/historia" element={<Historia/>} />
          <Route path="/proposta-pedagogica" element={<PropostaPedagogica/>} />
          <Route path="/hino" element={<Hino/>} />
          <Route path="/fundamental" element={<EnsinoFundamental/>} />
          <Route path="/infantil" element={<EnsinoInfantil/>} />
          <Route path="/sistema-tempo-integral" element={<SistemaTempoIntegral/>} />
          <Route path="/contato" element={<Contato/>} />
          <Route path="/matriculas" element={<Matriculas/>} />
          <Route path="/trabalhe-conosco" element={<TrabalheConosco/>} />

          <Route 
            path="/admin" 
            element={
              <PrivateRoute>
                <AdminHome />
              </PrivateRoute>
            } 
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      </main>
      {!isAdminRoute && <Footer />}
      {!isAdminRoute && <FloatWhatzapButton />}
      <CookiesConsent />
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
