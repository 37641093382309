import React, { useEffect, useState } from 'react';
import styles from './AdminEditProjetos.module.css';
import { updateProjeto } from '../../../services/projetoService';
import { storage } from '../../../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const AdminEditProjetos = ({ projeto, onEdit, onClose }) => {
    const [titulo, setTitulo] = useState('');
    const [subtitulo, setSubtitulo] = useState('');
    const [data, setData] = useState('');
    const [textoDescription, setTextoDescription] = useState('');
    const [texto, setTexto] = useState('');
    const [imagem, setImagem] = useState('');
    const [file, setFile] = useState(null); // Para armazenar o arquivo selecionado
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (projeto) {
            setTitulo(projeto.titulo);
            setSubtitulo(projeto.subtitulo);
            setData(projeto.data ? projeto.data.split('T')[0] : '');
            setTextoDescription(projeto.textoDescription);
            setTexto(projeto.texto);
            setImagem(projeto.imagem);
        }
    }, [projeto]);

    const handleFileUpload = async (file) => {
        const storageRef = ref(storage, `projetos/${projeto._id}_${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                null,
                (error) => reject(error),
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(downloadURL);
                }
            );
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUploading(true);

        try {
            let imagemUrl = imagem;

            if (file) {
                imagemUrl = await handleFileUpload(file); // Faz o upload e obtém a URL
            }

            await updateProjeto(projeto._id, {
                titulo,
                subtitulo,
                data,
                textoDescription,
                texto,
                imagem: imagemUrl,
            });

            onEdit(); // Atualiza a lista de projetos no componente pai
            onClose(); // Fecha o modal de edição
        } catch (error) {
            console.error('Erro ao atualizar projeto:', error);
        } finally {
            setUploading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <input
                type="text"
                placeholder="Título"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="text"
                placeholder="Subtítulo"
                value={subtitulo}
                onChange={(e) => setSubtitulo(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="date"
                placeholder="Data"
                value={data}
                onChange={(e) => setData(e.target.value)}
                className={styles.input}
                required
            />
            <input
                type="text"
                placeholder="Descrição"
                value={textoDescription}
                onChange={(e) => setTextoDescription(e.target.value)}
                className={styles.input}
                required
            />
            <textarea
                placeholder="Texto completo"
                value={texto}
                onChange={(e) => setTexto(e.target.value)}
                className={styles.textarea}
                required
            ></textarea>
            <div className={styles.fileUpload}>
                <label>
                    Anexar Imagem:
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                </label>
                {imagem && (
                    <img
                        src={imagem}
                        alt="Preview"
                        className={styles.previewImage}
                    />
                )}
            </div>
            {uploading ? (
                <p className={styles.uploadingText}>Fazendo upload...</p>
            ) : (
                <button type="submit" className={styles.button}>
                    Salvar Alterações
                </button>
            )}
        </form>
    );
};

export default AdminEditProjetos;
