import React, { useEffect, useState } from 'react';
import styles from './Projetos.module.css';
import { getProjetos } from '../services/projetoService';
import TituloComponent from '../components/Titulo/TituloComponent';
import { FaImage } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const Projetos = () => {
  const [projetos, setProjetos] = useState([]);
  const [verMais, setVerMais] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Rola para o topo ao carregar a página
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Suave
    });

    // Busca os projetos
    const fetchProjetos = async () => {
      const response = await getProjetos();
      setProjetos(response);
    };
    fetchProjetos();
  }, []);

  const toggleVerMais = (id) => {
    setVerMais((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className={styles.projetoContainer}>
      <TituloComponent name={"Projetos"} />
      <div className={styles.projetosLista}>
        {projetos.map((projeto) => (
          <div key={projeto._id} className={styles.projetoItem}>
            {/* Condição aprimorada para verificar imagem válida */}
            {projeto.imagem && projeto.imagem.trim() !== "" ? (
              <img src={projeto.imagem} alt={projeto.titulo} className={styles.projetoImagem} />
            ) : (
              <div className={styles.projetoImagemPlaceholder}>
                <FaImage className={styles.iconPlaceholder} />
              </div>
            )}
            <h2 className={styles.projetoTitulo}>{projeto.titulo}</h2>
            <h3 className={styles.projetoSubtitulo}>{projeto.subtitulo}</h3>
            <p className={styles.projetoData}>
              <strong>Data:</strong> {new Date(projeto.data).toLocaleDateString()}
            </p>

            {/* Descrição com "Ler mais" */}
            <p className={styles.projetoDescricao}>
              <strong>Descrição:</strong>{" "}
              {verMais[projeto._id]
                ? projeto.textoDescription
                : `${projeto.textoDescription.substring(0, 70)}...`}
              {projeto.textoDescription.length > 70 && (
                <span
                  className={styles.readMore}
                  onClick={() => toggleVerMais(projeto._id)}
                >
                  {verMais[projeto._id] ? " Ler menos" : " Ler mais"}
                </span>
              )}
            </p>

            {/* Botão Saiba Mais */}
            <button
              className={styles.saibaMaisButton}
              onClick={() => navigate(`/projeto/${projeto._id}`)}
            >
              Saiba mais
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projetos;
