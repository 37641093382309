import React from 'react';
import styles from './Historia.module.css';
import TituloComponent from '../components/Titulo/TituloComponent';
import img1 from '../assets/ImagemAntiga/image-16@2x.png';
import img2 from '../assets/ImagemAntiga/image-17@2x.png';

const Historia = () => {
    return (
        <div className={styles.historiaContainer}>
            <TituloComponent name="Nossa História" />
            <div className={styles.content}>
                <section className={styles.section}>
                    <p>
                        O Colégio Alternativo Colaço foi fundado em 10 de novembro de 1996 pela professora Mariza Colaço Pinheiro, recém-formada em Letras pela UECE. Com o apoio de suas irmãs, Ana Colaço Pinheiro, Felícia Colaço Pinheiro e Maria Mitchelle Colaço Pinheiro, o colégio nasceu com a missão de transformar a educação.
                    </p>
                </section>
                <section className={styles.section}>
                    <p>
                        Inicialmente chamado "Alternativo, Centro Integrado de Ensino", começou suas atividades em um prédio alugado na Rua Viriato Ribeiro, 614, atendendo exclusivamente à Educação Infantil. A inspiração veio de sua mãe, Maria Naironete Colaço Pinheiro, uma alfabetizadora dedicada e com afinidade especial pelo trabalho com crianças.
                    </p>
                </section>
                <section className={styles.section}>
                    <p>
                        Em novembro de 1998, o colégio conquistou sede própria, expandindo-se e obtendo autorização para oferecer o Ensino Fundamental. Com o tempo, o Alternativo Colaço consolidou-se como uma instituição de referência em qualidade e compromisso com o desenvolvimento integral de cada aluno.
                    </p>
                </section>
                <section className={styles.section}>
                    <p>
                        Desde 2004, após nova ampliação significativa, o colégio passou a se chamar oficialmente Colégio Alternativo Colaço, especializando-se no atendimento em período integral. Em 2024, alcançamos mais uma etapa de expansão, com novas salas de aula e ambientes de apoio especializados, reafirmando nosso compromisso com uma educação de qualidade, que atende às necessidades de cada criança.
                    </p>
                </section>
                <section className={styles.imageSection}>
                    <div className={styles.imageContainer}>
                        <img src={img1} alt="Família Colaço na fundação do colégio" className={styles.image} />
                        <p className={styles.caption}>Família Colaço na fundação do colégio, 1996</p>
                    </div>
                    <div className={styles.imageContainer}>
                        <img src={img2} alt="Mariza Colaço - Diretora e fundadora do colégio" className={styles.image} />
                        <p className={styles.caption}>Mariza Colaço - Diretora e fundadora do colégio</p>
                    </div>
                </section>
                <div className={styles.buttonContainer}>
                    <p className={styles.buttonInfo}>
                        Para agendar uma visita ou esclarecer dúvidas, clique no botão abaixo:
                    </p>
                    <button
                        className={styles.actionButton}
                        onClick={() => window.location.href = 'https://colegioalternativocolaco.com.br/contato'}
                    >
                        📋 Informações / Matrículas
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Historia;
