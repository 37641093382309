import React, { useEffect, useState } from "react";
import AOS from "aos"; // Importa a biblioteca AOS
import "aos/dist/aos.css"; // Importa os estilos do AOS
import styles from "./NoticiasSection.module.css";
import { getUltimasNoticias } from "../../../../services/NoticiasService";
import { FaImage } from "react-icons/fa6";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  }).format(date);
};

const NoticiasSection = () => {
  const [noticias, setNoticias] = useState([]);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duração da animação (1 segundo)
      once: true, // Executa a animação apenas uma vez
    });
  }, []);

  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const data = await getUltimasNoticias();
        const mappedNoticias = data.map((noticia) => ({
          id: noticia._id,
          titulo: noticia.titulo,
          descricao: noticia.textoDescription,
          data: formatDate(noticia.data),
          imagem: noticia.imagem,
        }));

        setNoticias(mappedNoticias);
      } catch (error) {
        console.error("Erro ao buscar notícias:", error);
      }
    };
    fetchNoticias();
  }, []);

  return (
    <div className={styles.noticiasContainer}>
      <h2
        className={styles.sectionTitle}
        data-aos="fade-up" // Título aparece de baixo para cima
      >
        Últimas Notícias
      </h2>
      <div className={styles.noticiasGrid}>
        {noticias.map((noticia, index) => (
          <div
            key={noticia.id}
            className={styles.noticiaCard}
            data-aos="fade-up" // Animação para cada cartão
            data-aos-delay={index * 100} // Atraso incremental para criar um efeito de cascata
          >
            {noticia.imagem && noticia.imagem.trim() !== "" ? (
              <img
                src={noticia.imagem}
                alt={noticia.titulo}
                className={styles.noticiaImage}
              />
            ) : (
              <div className={styles.noticiaImagePlaceholder}>
                <FaImage className={styles.iconPlaceholder} />
              </div>
            )}
            <div className={styles.noticiaContent}>
              <h3 className={styles.noticiaTitle}>{noticia.titulo}</h3>
              <p className={styles.noticiaDate}>{noticia.data}</p>
              <p className={styles.noticiaDescription}>{noticia.descricao}</p>
              <button
                className={styles.noticiaButton}
                onClick={() =>
                  (window.location.href = `/noticia/${noticia.titulo}`)
                }
              >
                Ler Mais
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NoticiasSection;
