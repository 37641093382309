import React, { useState, useEffect } from 'react';
import styles from './HeroSection2.module.css';
import Header2Service from '../../../../services/Header2Service';

const HeroSection2 = () => {
    const [headers, setHeaders] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

    // Fetch dos dados do Header2Service
    useEffect(() => {
        const fetchHeaders = async () => {
            try {
                const data = await Header2Service.getAll();
                if (data && data.length > 0) {
                    setHeaders(data);
                } else {
                    console.warn('Nenhum dado disponível.');
                }
            } catch (error) {
                console.error('Erro ao carregar os headers:', error);
            }
        };
        fetchHeaders();
    }, []);

    // Detecta redimensionamento para alternar entre imagens desktop e mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Alternância automática entre os slides
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % headers.length);
        }, 5000); // Troca de slide a cada 5 segundos

        return () => clearInterval(interval);
    }, [headers]);

    return (
        <section className={styles.heroContainer}>
            {headers.length > 0 ? (
                headers.map((header, index) => (
                    <div
                        key={header._id}
                        className={`${styles.slide} ${index === currentIndex ? styles.active : ''}`}
                        style={{
                            backgroundImage: `url(${
                                isMobile ? header.imagemMobile : header.imagemDesktop
                            })`,
                        }}
                    >
                        <div className={styles.overlay}></div>
                        {index === currentIndex && (
                            <h1 className={styles.heroText}>{header.titulo || 'Título não disponível'}</h1>
                        )}
                    </div>
                ))
            ) : (
                <p className={styles.noData}>Carregando slides...</p>
            )}
        </section>
    );
};

export default HeroSection2;
