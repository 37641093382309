import apiAxios from '../api/apiAxios';

const BASE_URL = '/header';

const HeaderService = {
    // Criar um novo Header
    create: async (data) => {
        try {
            const response = await apiAxios.post(`${BASE_URL}/`, data);
            return response.data;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    // Listar todos os Headers
    getAll: async () => {
        try {
            const response = await apiAxios.get(`${BASE_URL}/`);
            return response.data;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    // Buscar um Header por ID
    getById: async (id) => {
        try {
            const response = await apiAxios.get(`${BASE_URL}/${id}`);
            return response.data;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    // Atualizar um Header por ID
    update: async (id, data) => {
        try {
            const response = await apiAxios.put(`${BASE_URL}/${id}`, data);
            return response.data;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    // Deletar um Header por ID
    delete: async (id) => {
        try {
            const response = await apiAxios.delete(`${BASE_URL}/${id}`);
            return response.data;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    }
};

export default HeaderService;
