import React from 'react';
import styles from './Home.module.css';
import HeroSection from '../components/pagesComponents/Home/Hero/HeroSection';
import SobreSection from '../components/pagesComponents/Home/Sobre/SobreSection';
import VideoSec from '../components/pagesComponents/Home/Video/VideoSection';
import NoticiasSection from '../components/pagesComponents/Home/Noticias/NoticiasSection';
import VideoSection2 from '../components/pagesComponents/Home/Video2/VideoSection2';
import HeroSection2 from '../components/pagesComponents/Home/Hero/HeroSection2';
import DepoimentosSection from '../components/pagesComponents/Home/depoimentos/DepoimentosSection';
import FaqSection from '../components/pagesComponents/Home/Faq/FaqSection';
import BeneficiosSection from '../components/pagesComponents/Home/Beneficios/BeneficiosSection';
import ProjetosSection from '../components/pagesComponents/Home/Projetos/ProjetosSection';

const Home = () => {
  return (
    <div className={styles.homeContainer}>
      <HeroSection />
      <SobreSection />
      <VideoSec />
      <NoticiasSection />
      <VideoSection2 />
      <HeroSection2/>
      <DepoimentosSection/>
      <BeneficiosSection/>
      <ProjetosSection/>
      <FaqSection/>
    </div>
  );
};

export default Home;
