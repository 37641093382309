// FloatWhatzapButton.js
import React from 'react';
import styles from './FloatWhatzapButton.module.css';
import img from '../../assets/FloatImages/Podemos Ajudar.png';

const FloatWhatzapButton = () => {
  const whatsappLink = "https://api.whatsapp.com/send/?phone=5588988068633&text=Ol%C3%A1%2C+gostaria+de+informa%C3%A7%C3%B5es+sobre+matr%C3%ADculas+no+Col%C3%A9gio+Alternativo+Cola%C3%A7o&type=phone_number&app_absent=0";

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className={styles.floatButton}>
      <img src={img} alt="Podemos Ajudar" className={styles.icon} />
    </a>
  );
};

export default FloatWhatzapButton;
