import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ProjetosDetail.module.css';
import { getProjetoByTitulo } from '../services/projetoService';
import { FaImage } from "react-icons/fa6";

const ProjetosDetail = () => {
  const { titulo } = useParams(); // Captura o título do projeto da URL
  const [projeto, setProjeto] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjeto = async () => {
      try {
        const response = await getProjetoByTitulo(titulo);
        setProjeto(response);
      } catch (error) {
        console.error("Erro ao buscar o projeto:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjeto();
  }, [titulo]);

  if (loading) return <div className={styles.loading}>Carregando...</div>;
  if (!projeto) return <div className={styles.notFound}>Projeto não encontrado.</div>;

  return (
    <div className={styles.projetoFull}>
      <div className={styles.projetoContainer}>
        <h1 className={styles.projetoTitulo}>{projeto.titulo}</h1>
        <p className={styles.projetoData}>
          <strong>Data:</strong> {new Date(projeto.data).toLocaleDateString()}
        </p>
        <div className={styles.projetoImagemContainer}>
          {projeto.imagem ? (
            <img src={projeto.imagem} alt={projeto.titulo} className={styles.projetoImagem} />
          ) : (
            <div className={styles.projetoImagemPlaceholder}>
              <FaImage className={styles.iconPlaceholder} />
            </div>
          )}
        </div>
        <h3 className={styles.projetoSubtitulo}>{projeto.subtitulo}</h3>
        <p className={styles.projetoDescricao}><strong>Descrição:</strong> {projeto.textoDescription}</p>
        <div className={styles.projetoTexto}>{projeto.texto}</div>
      </div>
    </div>
  );
};

export default ProjetosDetail;
