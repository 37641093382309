import React, { useState } from 'react';
import styles from './FaqSection.module.css';

const faqData = [
  {
    question: 'Como funciona o processo de matrícula?',
    answer: 'O processo de matrícula começa com o preenchimento do formulário online. Em seguida, nossa equipe entrará em contato para agendar uma visita e finalizar o processo.',
  },
  {
    question: 'Quais atividades extracurriculares a escola oferece?',
    answer: 'Oferecemos atividades como teatro, musicalização, iniciação científica, esportes e inglês musicalizado, entre outras.',
  },
  {
    question: 'Qual é o horário de funcionamento da escola?',
    answer: 'A escola funciona de segunda a sexta, das 7h30 às 17h30, com opções de semi-integral e integral.',
  },
  {
    question: 'Como é feita a adaptação dos novos alunos?',
    answer: 'A adaptação é feita gradualmente, com apoio dos professores e equipe pedagógica para garantir o bem-estar e o acolhimento do aluno.',
  },
  // Adicione mais perguntas e respostas conforme necessário
];

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className={styles.faqContainer}>
      <h2 className={styles.title}>Perguntas Frequentes</h2>
      <div className={styles.faqList}>
        {faqData.map((item, index) => (
          <div key={index} className={styles.faqItem}>
            <div className={styles.question} onClick={() => toggleAnswer(index)}>
              {item.question}
              <span className={styles.icon}>{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && <div className={styles.answer}>{item.answer}</div>}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FaqSection;
