import React, { useEffect } from "react";
import AOS from "aos"; // Importa o AOS
import "aos/dist/aos.css"; // Importa os estilos do AOS
import styles from "./VideoSection2.module.css";

const VideoSection2 = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duração das animações
      once: true, // Executa apenas uma vez
    });
  }, []);

  return (
    <section className={styles.videoContainer}>
      <h2
        className={styles.title}
        data-aos="fade-down" // Animação de cima para baixo
      >
        Assista aos nossos vídeos
      </h2>
      <div className={styles.videos}>
        <div
          className={styles.videoWrapper}
          data-aos="fade-right" // Animação vinda da direita
          data-aos-delay="200" // Pequeno atraso
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/Z6seoqA_6Ag"
            title="Vídeo 1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div
          className={styles.videoWrapper}
          data-aos="fade-left" // Animação vinda da esquerda
          data-aos-delay="400" // Atraso maior
        >
          <iframe
            width="100%"
            height="100%"
            src="https://youtube.com/embed/t6XZqgI13yI?si=5_vIB2v6cLLkf1oW"
            title="Vídeo 2"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <p
        className={styles.infoText}
        data-aos="fade-up" // Animação de baixo para cima
        data-aos-delay="600"
      >
        PARA AGENDAR VISITA OU ESCLARECER DÚVIDAS CLIQUE NO BOTÃO ABAIXO:
      </p>
      <button
        className={styles.actionButton}
        onClick={() => (window.location.href = "/contato")}
        data-aos="fade-up" // Animação de baixo para cima
        data-aos-delay="800"
      >
        📋 INFORMAÇÕES / MATRÍCULAS
      </button>
    </section>
  );
};

export default VideoSection2;
