import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import styles from './ProjetosSection.module.css';
import { getProjetos } from '../../../../services/projetoService';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaImage } from "react-icons/fa6";

const ProjetosSection = () => {
    const [projetos, setProjetos] = useState([]);

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2 } },
            { breakpoint: 768, settings: { slidesToShow: 1 } },
        ],
    };

    useEffect(() => {
        const fetchProjetos = async () => {
            try {
                const data = await getProjetos();
                const mappedProjetos = data.map((projeto) => ({
                    id: projeto._id,
                    image: projeto.imagem,
                    title: projeto.titulo,
                    subtitle: projeto.subtitulo,
                    date: projeto.data,
                    overview: projeto.textoDescription,
                    text: projeto.text,
                }));
                setProjetos(mappedProjetos);
            } catch (error) {
                console.error("Erro ao buscar projetos:", error);
            }
        };
        fetchProjetos();
    }, []);

    return (
        <div className={styles.projetosSection}>
            <h2>Projetos do Colégio</h2>
            <Slider {...settings} className={styles.projetosSlider}>
                {projetos.map((projeto) => (
                    <div key={projeto.id} className={styles.projetoCard}>
                        {projeto.image && projeto.image.trim() !== "" ? (
                            <img src={projeto.image} alt={projeto.title} className={styles.projetoImage} />
                        ) : (
                            <div className={styles.projetoImagePlaceholder}>
                                <FaImage className={styles.iconPlaceholder} />
                            </div>
                        )}
                        <div className={styles.projetoInfo}>
                            <h3>{projeto.title}</h3>
                            <p className={styles.projetoSubtitle}>{projeto.subtitle}</p>
                            <p className={styles.projetoDate}>{new Date(projeto.date).toLocaleDateString('pt-BR')}</p>
                            <OverviewText text={projeto.overview} />
                            <button
                                className={styles.saibaMaisButton}
                                onClick={() => (window.location.href = `/projeto/${projeto.title}`)}
                            >
                                Saiba mais
                            </button>
                        </div>
                    </div>
                ))}
            </Slider>
            <Link to="/todos-projetos" className={styles.verTodosButton}>
                Ver Todos
            </Link>
        </div>
    );
};

const OverviewText = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const MAX_LENGTH = 70;

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <p className={styles.projetoOverview}>
            {isExpanded ? text : `${text.substring(0, MAX_LENGTH)}...`}
            {text.length > MAX_LENGTH && (
                <span className={styles.readMore} onClick={toggleReadMore}>
                    {isExpanded ? ' Ler menos' : ' Ler mais'}
                </span>
            )}
        </p>
    );
};

export default ProjetosSection;
