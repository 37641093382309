import React from 'react';
import styles from './PropostaPedagogica.module.css';
import TituloComponent from '../components/Titulo/TituloComponent';

const PropostaPedagogica = () => {
    return (
        <div className={styles.propostaContainer}>
            <TituloComponent name="Proposta Pedagógica" />
            <div className={styles.content}>
                <section className={styles.section}>
                    <p>
                        A proposta pedagógica do <strong>COLÉGIO ALTERNATIVO COLAÇO</strong> é fundamentada nos talentos e direitos de aprendizagem estabelecidos pela BNCC (Base Nacional Comum Curricular), visando o desenvolvimento integral das crianças desde a Educação Infantil até o Ensino Fundamental – Anos Iniciais. Nossa abordagem prioriza seis direitos de aprendizagem: conviver, brincar, participar, explorar, expressar e conhecer-se, que são essenciais para que as crianças tenham uma educação significativa e contextualizada.
                    </p>
                </section>
                <section className={styles.section}>
                    <p>
                        No ambiente escolar, buscamos criar situações de aprendizagem que incentivem os alunos a desempenharem um papel ativo, enfrentando desafios e construindo significados sobre si mesmos, os outros e o mundo ao seu redor. Valorizamos as experiências lúdicas na Educação Infantil e a articulação progressiva dessas experiências nos Anos Iniciais do Ensino Fundamental, proporcionando aos alunos novas formas de relacionar-se com o conhecimento e de desenvolver habilidades de leitura, formulação de hipóteses, testagem e elaboração de conclusões.
                    </p>
                </section>
                <section className={styles.section}>
                    <p>
                        Entendemos que cada criança é única e possui potenciais diversos que devem ser explorados e desenvolvidos em um ambiente de aprendizagem satisfatório e construtivo. Nosso compromisso é oferecer uma educação que promova a autonomia, a individualidade, a autoconfiança e a descoberta das aptidões vocacionais de cada aluno, preparando-os para enfrentar os desafios do mundo atual com segurança e competência.
                    </p>
                </section>
                <section className={styles.signature}>
                    <p>Mariza Colaço Pinheiro,</p>
                    <p>Diretora Pedagógica.</p>
                </section>
            </div>
        </div>
    );
};

export default PropostaPedagogica;
