// src/pages/Login.js
import React, { useState, useContext, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import styles from './Login.module.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext'; // Importar o AuthContext

function Login() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [erro, setErro] = useState('');
  const navigate = useNavigate();
  const { usuarioAtual } = useContext(AuthContext); // Acessar o contexto

  useEffect(() => {
    if (usuarioAtual) {
      navigate('/admin'); // Redirecionar se já estiver logado
    }
  }, [usuarioAtual, navigate]);

  const handleLogin = (e) => {
    e.preventDefault();
    setErro(''); // Resetar erro antes de tentar login
    signInWithEmailAndPassword(auth, email, senha)
      .then((userCredential) => {
        // Login bem-sucedido
        const user = userCredential.user;
        console.log('Usuário logado:', user);
        navigate('/admin'); // Redirecionar para /admin após login
      })
      .catch((error) => {
        // Tratar erros
        console.error('Erro ao fazer login:', error);
        setErro('Email ou senha inválidos. Por favor, tente novamente.');
      });
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleLogin} className={styles.form}>
        <h2>Login</h2>
        {erro && <p className={styles.error}>{erro}</p>}
        <div className={styles.inputGroup}>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="senha">Senha</label>
          <input
            id="senha"
            type="password"
            placeholder="Digite sua senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            required
          />
        </div>
        <button type="submit" className={styles.button}>Entrar</button>
      </form>
    </div>
  );
}

export default Login;
