import React, { useState, useEffect } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { GiHamburgerMenu } from "react-icons/gi"; 
import styles from './Header.module.css';
import logo from '../../assets/LogoColegio/Logo Alternativo.f3ed8d35cac5774609c4.png';
import PopUpComponent from '../popup/PopUpComponent';
import { useNavigate } from 'react-router-dom';

export const Header = () => {
    const [showHeader, setShowHeader] = useState(true);
    const [lastScrollPos, setLastScrollPos] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);
    const [openSubmenu, setOpenSubmenu] = useState(null);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 700);
    const navigate = useNavigate();

    const navLinks = [
        { name: 'Home', href: '/' },
        {
            name: 'Institucional', href: '/programas', tree: true, children: [
                { name: 'História', href: '/historia' },
                { name: 'Hino', href: '/hino' },
                { name: 'Proposta Pedagógica', href: '/proposta-pedagogica' },
                { name: 'Notícias', href: '/todos-noticias' },
                { name: 'Projetos', href: '/todos-projetos' },
                { name: 'Trabalhe conosco', href: '/trabalhe-conosco' },
            ]
        },
        {
            name: 'Cursos', href: '/programas', tree: true, children: [
                { name: 'Educação Infantil', href: '/infantil' },
                { name: 'Educação Fundamental I', href: '/fundamental' },
                { name: 'Sistema de tempo integral', href: '/sistema-tempo-integral' },
            ]
        },
        {
            name: 'Sistema online', href: '/programas', tree: true, children: [
                { name: 'Alunos, Pais e Responsáveis', href: 'https://web.educacaoalternativa360.com.br/' },
                { name: 'Professores e Coordenadores', href: 'https://escola.educacaoalternativa360.com.br/' }
            ]
        },
        { name: 'Contato', href: '/contato' }
    ];

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setShowHeader(currentScrollPos <= lastScrollPos);
            setLastScrollPos(currentScrollPos);
        };

        const handleResize = () => {
            setIsDesktop(window.innerWidth > 800);
            if (window.innerWidth > 800) {
                setMenuOpen(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [lastScrollPos]);

    useEffect(() => {
        document.body.style.overflow = menuOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [menuOpen]);

    const toggleMenu = () => setMenuOpen((prev) => !prev);

    const handleMouseEnter = (index) => {
        setOpenSubmenu(index);
    };

    const handleMouseLeave = () => {
        setOpenSubmenu(null);
    };

    return (
        <>
            <header className={`${styles.header} ${showHeader ? styles.show : styles.hide}`}>
                <div className={styles.logoContainer}>
                    <img src={logo} alt="Colégio Alternativo Colaço" className={styles.logo} onClick={() => navigate("/")} />
                </div>
                {isDesktop ? (
                    <>
                        <div className={styles.navLinksContainer}>
                            {navLinks.map((link, index) => (
                                <div
                                    key={index}
                                    className={styles.navItem}
                                    onMouseEnter={() => link.tree && handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {link.tree ? (
                                        <span className={styles.navLink}>
                                            {link.name}
                                            <FiChevronDown className={styles.arrowIcon} />
                                        </span>
                                    ) : (
                                        <a href={link.href} className={styles.navLink}>{link.name}</a>
                                    )}
                                    {link.tree && openSubmenu === index && (
                                        <div className={styles.submenu}>
                                            {link.children.map((child, idx) => (
                                                <a key={idx} href={child.href} className={styles.submenuLink}>
                                                    {child.name}
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <button className={styles.matriculasAbertas} onClick={()=>navigate("/matriculas")}>🎒 Matrículas Abertas</button>
                    </>
                ) : (
                    <div className={styles.menuWrapper}>
                        <GiHamburgerMenu onClick={toggleMenu} className={styles.menuIcon} />
                    </div>
                )}
            </header>

            <PopUpComponent isOpen={menuOpen} onClose={toggleMenu}>
                {navLinks.map((link, index) => (
                    <div key={index} className={styles.navItem}>
                        <div className={styles.navLinkWrapper}>
                            <a
                                href={link.href}
                                className={styles.navLink}
                                onClick={(e) => {
                                    if (link.tree) e.preventDefault();
                                    setOpenSubmenu(openSubmenu === index ? null : index);
                                }}
                            >
                                {link.name}
                            </a>
                            {link.tree && (
                                <FiChevronDown
                                    className={`${styles.arrowIcon} ${openSubmenu === index ? styles.arrowOpen : ''}`}
                                    onClick={() => setOpenSubmenu(openSubmenu === index ? null : index)}
                                />
                            )}
                        </div>
                        {link.tree && openSubmenu === index && (
                            <div className={styles.modalSubmenu}>
                                {link.children.map((child, idx) => (
                                    <a key={idx} href={child.href} className={styles.modalSubmenuLink}>
                                        {child.name}
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </PopUpComponent>
        </>
    );
};
